var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contract-component-kline",style:({
    height: _vm.isFullScreen ? 'calc(100vh - 160px)' : '',
    marginBottom: _vm.isFullScreen ? '50px' : '',
  })},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.currViewType == 1),expression:"currViewType == 1"}],staticClass:"kline-full",attrs:{"src":_vm.isFullScreen ? _vm.zoom_screen : _vm.full_screen},on:{"click":_vm.zoomKline}}),_c('div',{staticClass:"resolution-btn-group"},[_c('ul',{staticClass:"resolution-list"},_vm._l((_vm.resolutionList),function(item){return _c('li',{key:item.key,class:{
          active: _vm.currResolution == item.value && _vm.currChartType == item.type,
        },on:{"click":function($event){return _vm.onResolutionChange(item)}}},[_c('span',[_vm._v(_vm._s(_vm.translateTitle(item.label)))]),_c('i')])}),0),(!_vm.isFullScreen)?_c('ul',{staticClass:"view-list"},_vm._l((_vm.viewType),function(item){return _c('li',{key:item.key,class:{
          active: _vm.currViewType == item.value,
        },on:{"click":function($event){return _vm.onViewChange(item.value)}}},[_c('span',[_vm._v(_vm._s(_vm.translateTitle(item.label)))]),_c('i')])}),0):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currViewType == 1),expression:"currViewType == 1"},{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],style:({ height: _vm.isFullScreen ? '100%' : '' }),attrs:{"id":"tv_chart_container"}}),(!_vm.isFullScreen)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.currViewType == 2 && _vm.chartLoading),expression:"currViewType == 2 && chartLoading"}],staticStyle:{"width":"auto","height":"100%"}},[(_vm.currViewType == 2)?_c('v-chart',{ref:"deepechart",attrs:{"options":_vm.deepOptions,"id":"contract-deep-chart"}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }