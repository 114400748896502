import api from "@/assets/js/api";
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

export function apiGetDefaultToken(data = {}) {
  return api({
    url: VUE_APP_APP + `/Publics/getDefaultToken`,
    data,
    type: "POST",
  });
}
// export function apiWsUrl(data = {}) {
//   return api({
//       url: VUE_APP_UCENTER + `/UCenter/Users/getInitInfo`,
//       data,
//       type: "POST"
//   })
// }
export function apiWsUrl(data = {}) {
  return api({
    url: VUE_APP_APP + `/Publics/getWebInitInfo`,
    data,
    type: "POST",
  });
}
// 币币交易
export function apiSwitchCoin(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/TradeCoin/switchCoin`,
    data,
    type: "POST",
  });
}
// 限价委托 （触发单）
export function apiLimitOrder(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/TradeCoin/limitOrder`,
    data,
    type: "POST",
  });
}
//获取币币币种
export function apiGetSpotList(data = {}) {
  return api({
    url: VUE_APP_APP + `/MarketV2/getSpotList`,
    data,
    type: "POST",
  });
}
//委托下单
export function apiTrustStore(data = {}) {
  return api({
    url: VUE_APP_APP + `/Spot/Trade/trustStore`,
    data,
    type: "POST",
  });
}
//获取用户资产
export function apiGetAssets(data = {}) {
  return api({
    url: VUE_APP_APP + `/AssetsV2/getAssets`,
    data,
    type: "POST",
  });
}
//获取用户资产头信息
export function apiGetWebAssets(data = {}) {
  return api({
    url: VUE_APP_APP + `/AssetsV2/getWebAssets`,
    data,
    type: "POST",
  });
}
//获取用户资产
export function apiGetOrderbook(data = {}) {
  return api({
    url: VUE_APP_APP + `/Web/Kline/orderbook`,
    data,
    type: "POST",
  });
}
