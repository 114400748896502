<template>
  <el-scrollbar id="app" :class="getLanguageClass">
    <Header @onApiClick="onApiClick"></Header>
    <div class="app-main">
      <router-view v-if="$route.meta.title == '首页'"></router-view>
      <router-view
        v-else
        v-wechat-title="
          translateTitle($route.meta.title) + ' | ' + translateTitle(title)
        "
      ></router-view>
    </div>
    <div class="top-icon" @click="onTopClick" v-show="gotop">
      <img src="./assets/img/m/top-icon.png" alt="" />
    </div>
    <ChatWedget ref="ChatWedget" />
    <Footer v-if="!hideFooter" @onApiClick="onApiClick"></Footer>
  </el-scrollbar>
</template>
<script>
import Header from "@/components/common/header.vue";
import Footer from "@/components/common/footer.vue";
import { translateTitle } from "@/assets/js/i18n";
import ChatWedget from "./pages/customer-service/ChatWedget.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    ChatWedget,
  },
  data() {
    return {
      title: "数字货币银行",
      groupId: "20",
      hideFooter: false,
      gotop: false,
      isRouterAlive: true,
      sessionVariables: {},
      countryList: [
        {
          id: "English",
          value: "en",
          id: "22",
        },
        {
          name: "日本語",
          value: "ja",
          id: "24",
        },
        {
          name: "한국인",
          value: "ko",
          id: "23",
        },

        {
          name: "Français",
          value: "fr",
          id: "26",
        },
        {
          name: "Español",
          value: "es",
          id: "27",
        },
        {
          name: "Pусский",
          value: "ru",
          id: "28",
        },
        {
          name: "Deutsch",
          value: "de",
          id: "29",
        },
        {
          name: "Português",
          value: "pt",
          id: "30",
        },
        {
          name: "हिंदी",
          value: "hi",
        },
        {
          name: "Tiếng Việt",
          value: "vi",
          id: "32",
        },
        {
          name: "Pilipino",
          value: "tl",
          id: "33",
        },
        {
          name: "ภาษาไทย",
          value: "th",
          id: "34",
        },
        {
          name: "Türk",
          value: "tr",
          id: "35",
        },
        {
          name: "Italiano",
          value: "it",
          id: "36",
        },
        {
          name: "bahasa Indonesia",
          value: "id",
        },
        {
          name: "عربي",
          value: "ar",
          id: "25",
        },
        {
          name: "中文简体",
          value: "zh_CN",
          id: "20",
        },
        {
          name: "中文繁體",
          value: "zh_HK",
          id: "21",
        },
      ],
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  watch: {
    userInfo() {
      if (this.userInfo) {
        this.sessionVariables.uid = this.userInfo.accountInfo.account_id;
      }
    },
  },
  created() {
    this.setTheme();
    this.$router.afterEach(() => {
      this.isHasFooter();
    });
    this.$router.beforeEach((to, from, next) => {
      this.isHasFooter();
      next();
    });
    this.isHasFooter();
    setInterval(() => {
      window.location.reload();
    }, 3 * 60 * 60 * 1000);
    if (this.userInfo) {
      this.sessionVariables.uid = this.userInfo.accountInfo.account_id;
    }
    this.countryList.forEach(ele => {
      if (ele.value == this.language) {
        this.groupId = ele.id;
      }
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      darkMode: "user/darkMode",
    }),

    getLanguageClass() {
      let tempClass = "";
      tempClass = this.language;
      if (tempClass == "" || tempClass == null || tempClass == undefined) {
        tempClass = "zh_CN";
      }
      return tempClass;
    },
  },
  methods: {
    translateTitle,
    handleNewEvent(event) {
      console.log("LiveChatWidget.onNewEvent", event);
    },
    ...mapActions({
      changeLanguage: "user/changeLanguage",
      changeCurrency: "user/changeCurrency",
      changeDarkMode: "user/changeDarkMode",
    }),
    tipsDownload() {
      this.$refs.header8v.onDownloadTipsDialog();
    },
    onApiClick() {
      this.$refs.ChatWedget.onChatClick();
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      scrollTop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    onTopClick() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    setTheme() {
      this.title = "轻松理财，享受生活，理性投资，双Q护航";
      document.getElementsByTagName("body")[0].className = "vab-theme-doge";
    },

    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    isHasFooter() {
      const index = location.pathname.indexOf("/login");
      const index1 = location.pathname.indexOf("/register");
      const index2 = location.pathname.indexOf("/assets");
      const index3 = location.pathname.indexOf("/forgetPwd");
      const index4 = location.pathname.indexOf("/LoginSecurity");
      if (index != -1 || index1 != -1 || index3 != -1 || index4 != -1) {
        this.hideFooter = true;
      } else {
        this.hideFooter = false;
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  &.fr {
    .app-main .side-menu {
      width: 266px;
    }
  }
  &.es {
    .app-main .side-menu {
      width: 280px;
    }
  }
  &.ru {
    .app-main .side-menu {
      width: 280px;
    }
  }
  &.de {
    .app-main .side-menu {
      width: 260px;
    }
  }
  &.vi {
    .app-main .side-menu {
      width: 260px;
    }
  }
  &.it {
    .app-main .side-menu {
      width: 260px;
    }
  }
  &.pt {
    .app-main .side-menu {
      width: 270px;
    }
  }
  &.tl {
    .app-main .side-menu {
      width: 280px;
    }
  }
  &.ar {
    .app-main {
      .home-page {
        // direction: rtl;
      }
    }
  }
}
#app {
  display: flex;
  font-family: PingFang SC, robotoregular, Hiragino Sans GB, Heiti SC,
    Microsoft YaHei, WenQuanYi Micro Hei, Helvetica, Arial, monospace, serif !important;
  flex-direction: column;
  font-style: normal;
  // min-width: 1440px;
  height: 100%;
  min-width: 1180px;
  background: #ecedf1;
  &.ar {
    .el-scrollbar__wrap {
      margin-right: 0;
      margin-left: -15px;
    }
  }
  .el-scrollbar__wrap {
    height: 102%;
  }
  .el-scrollbar__view {
    height: 100%;
  }
  .app-main {
    flex: 1;
    min-height: calc(100% - 281px);
    .side-menu {
      padding: 0px 0;
      background: rgba(255, 255, 255, 1);
      // border-radius: 4px;
      overflow: hidden;
      width: 206px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
      z-index: 999;
      height: auto;
      float: left;

      .nav {
        li {
          text-align: left;
          justify-content: left;
          padding-left: 30px;
          box-sizing: border-box;
          height: 40px; // jason要求改为40

          i {
            margin-right: 15px;
          }
        }
        li.active {
          color: #fff;
          background: linear-gradient(
            315deg,
            rgba(39, 198, 131, 1) 0%,
            rgba(39, 198, 131, 1) 100%
          );
        }
      }
    }
    .el-form-item {
      text-align: left !important;
    }
  }
  .top-icon {
    position: fixed;
    right: 40px;
    bottom: 14%;
    cursor: pointer;
    z-index: 99999;
  }
}
</style>
