<template>
  <div class="page-footer">
    <div class="first-content">
      <div class="page-footer-content">
        <div>
          <ul class="logo-content">
            <li>
              <a href="/">
                <img :src="logImg" alt="" />
              </a>
            </li>
            <li class="sub-title slogan">
              <!-- {{ translateTitle("让金融更高效，让财富更自由") }}
             -->
              <span class="web-slogan">
                {{ slogan["web_slogan_" + language] }}
              </span>
              <a :href="tgUrl" target="_blank" v-if="isbitda">
                <img
                  class="bitda-tg"
                  src="../../assets/img/home/bitda/bitda-tg-icon.png"
                  alt=""
                />
              </a>
            </li>

            <li class="footer-icons" v-if="!is8V && !isbitda">
              <a :href="tgUrl" target="_blank">
                <i class="iconfont iconf-4"> </i>
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul class="tg-content">
            <qrcode-vue
              class="qr_code_canvas"
              v-show="tgUrl"
              :value="tgUrl"
              :size="78"
              level="H"
            ></qrcode-vue>
            <li>{{ translateTitle(qrcodeTitle) }}</li>
          </ul>
        </div>
        <div>
          <ul class="text-content">
            <li class="sub-title">{{ translateTitle("关于") }}</li>
            <a :href="tipUrl" target="_blank">
              <li>{{ translateTitle("公告中心") }}</li>
            </a>
            <a :href="helpUrl" target="_blank">
              <li>{{ translateTitle("帮助中心") }}</li>
            </a>
            <a href="/aboutus" target="_blank" v-if="is8V">
              <li>{{ translateTitle("关于我们") }}</li>
            </a>
          </ul>
        </div>

        <div>
          <ul class="text-content">
            <li class="sub-title">{{ translateTitle("条款") }}</li>
            <a :href="serviceUrl" target="_blank">
              <li>{{ translateTitle("服务协议") }}</li>
            </a>
            <a :href="privacyUrl" target="_blank">
              <li>{{ translateTitle("隐私协议") }}</li>
            </a>
            <a :href="statementUrl" target="_blank">
              <li>{{ translateTitle("免责声明") }}</li>
            </a>
            <span target="_blank" @click="onKycClick" v-if="isbitda">
              <li>{{ translateTitle("KYC政策") }}</li>
            </span>
          </ul>
        </div>
        <div>
          <ul class="text-content">
            <li class="sub-title">{{ translateTitle("其他") }}</li>
            <a :href="rateUrl" target="_blank">
              <li>{{ translateTitle("费率标准") }}</li>
            </a>
            <a :href="apiUrl" target="_blank" v-if="hasAPI">
              <li>{{ translateTitle("API") }}</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
    <div class="second-content V2 V3" v-if="isbitda">
      <div>
        <p>
          BITDA LIMITED CANADA MSB number：M22902291
        </p>
        <p>
          ADDRESS：1530 ALBION ROAD TORONTO ON M9V 5H4
        </p>
      </div>
      <div>
        <p>
          BITDA LIMITED U.S MSB number：31000205754185
        </p>
        <p>ADDRESS：4255 South Buckley Road #1296 Aurora COLORADO 80013</p>
      </div>
    </div>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import QrcodeVue from "qrcode.vue";
import { mapGetters } from "vuex";
import { getEnv } from "@/assets/js/stationSetting";
export default {
  components: {
    QrcodeVue,
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
  },
  data() {
    return {
      slogan: "让金融更高效 让财富更自由",
      tgUrl: "",
      tipUrl: "",
      qrcodeTitle: "",
      rateUrl: "",
      serviceUrl: "",
      privacyUrl: "",
      statementUrl: "",
      helpUrl: "",
      projectName: "",
      logImg: "",
      apiUrl: "",
      is8V: false,
      isbitda: false,
      hasAPI: false,
    };
  },
  created() {
    this.logImg = require("../../assets/img/logo_doge.png");
    this.slogan = "doge交易所，用信用成就梦想";
    this.slogan = JSON.parse(sessionStorage.getItem("solgan"));

    if (!JSON.parse(sessionStorage.getItem("clause"))) {
      getEnv();
    }
    let clauseList = JSON.parse(sessionStorage.getItem("clause"));
    let othersList = JSON.parse(sessionStorage.getItem("others"));
    let noticeList = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    let qrcode = JSON.parse(sessionStorage.getItem("qrcode"));
    let about = JSON.parse(sessionStorage.getItem("about"));

    if (othersList[0].language) {
      this.rateUrl = othersList[0].language[`url_${this.language}`];
    } else {
      this.rateUrl = othersList[0].url;
    }
    if (othersList[1]) {
      this.hasAPI = true;
      this.apiUrl = othersList[1].url;
    } else {
    }

    if (clauseList[0].language) {
      this.serviceUrl = clauseList[0].language[`url_${this.language}`];
    } else {
      this.serviceUrl = clauseList[0].url;
    }
    if (about[0].language) {
      this.tipUrl = about[0].language[`url_${this.language}`];
    } else {
      this.tipUrl = about[0].url;
    }

    if (clauseList[1].language) {
      this.privacyUrl = clauseList[1].language[`url_${this.language}`];
    } else {
      this.privacyUrl = clauseList[1].url;
    }

    if (clauseList[2].language) {
      this.statementUrl = clauseList[2].language[`url_${this.language}`];
    } else {
      this.statementUrl = clauseList[2].url;
    }
    if (about[1].language) {
      this.helpUrl = about[1].language[`url_${this.language}`];
    } else {
      this.helpUrl = about[1].url;
    }

    this.tgUrl = qrcode.ori_url;
    this.qrcodeTitle = qrcode.title;
  },
  methods: {
    translateTitle,
    onKycClick() {
      this.$router.push({
        path: "/kyc-notice",
      });
    },
    onAPIClick() {
      this.$emit("onApiClick");
    },
    onClickMore() {
      window.open(this.tipUrl);
    },
  },
};
</script>
<style lang="scss">
.page-footer {
  padding: 50px 0 20px;
  // position: fixed;
  // bottom: 0px;
  // left: 0px;
  .first-content {
    display: flex;
    justify-content: center;
  }
  .second-content {
    width: 1000px;
    margin: 0px auto;
    padding: 20px 90px;
    border-top: 1px solid #fdac35;
    color: #fdac35;
    &.V2 {
      border-top: 1px solid #7e8392;
      color: #7e8392;
    }
    &.V3 {
      border-top: none;
      margin-top: 20px;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      p {
        margin-bottom: 0;
        color: #786b8e;
      }
    }
    p {
      font-size: 14px;
      line-height: 2;
      margin-bottom: 10px;
      vertical-align: middle;
      img {
        width: 14px;
        height: 16px;
        vertical-align: middle;
      }
    }
  }
  background-color: #17232c;
  color: #fff;
  text-align: center;
  user-select: none;

  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  .page-footer-content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    // padding: 0px 360px;
    width: 1000px;
    .logo-content img {
      height: 42px;
    }

    .footer-icons {
      margin-top: 30px;
      .iconfont {
        width: 16px;
        height: 12px;
        color: #7e8392;
        margin-right: 16px;
        cursor: pointer;
        &:hover {
          color: #357de1;
          // width: 20px;
          // height: 20px;
          // border-radius: 50%;
          // color: #ffffff;
        }
      }
    }
    .logo-content {
      text-align: left;
      img {
        width: 182px;
        height: 42px;
      }
      .sub-title {
        font-size: 14px;
        color: #7e8392;
        height: 20px;
        margin-top: 10px;
        vertical-align: middle;
        // &.slogan {
        //   width: 160px;
        // }
        .bitda-tg {
          width: 32px;
          height: 27px;
          margin-left: 13px;
          vertical-align: middle;
        }
      }
    }
    .tg-content {
      .qr_code_canvas {
        margin-bottom: 10px;
        canvas {
          border: 3px solid white;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
      li {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    .text-content {
      vertical-align: top;
      li {
        color: #ffffff;
        font-size: 12px;
        margin: 0 0 17px;
        cursor: pointer;
        &:hover {
          color: rgba(255, 255, 255, 0.7);
        }
      }
      .sub-title {
        font-size: 16px;
        color: #7e8392;
        &:hover {
          color: #7e8392;
        }
      }
    }
  }
}
</style>
