<template>
  <div class="finance-dialog-content" v-loading="pageLoading">
    <div class="left-item" v-if="pageLoading"></div>
    <div class="left-item" v-else>
      <div class="first-content-1" v-if="productInfo.product_type == 1">
        <!-- min-width: 360px; -->
        <div style="">
          <div>
            <img :src="productInfo.item_url" alt="" />
            <span class="first-test">{{ productInfo.product_name }}</span>
            <div
              v-if="
                productInfo.product_type == 1 &&
                  (productInfo.status == 1 || productInfo.status == 2)
              "
              class="schedule warming"
            >
              {{ translateTitle("募集预热中") }}
            </div>
            <div
              v-if="
                productInfo.product_type != 2 &&
                  productInfo.period != 2 &&
                  productInfo.status == 3
              "
              class="schedule recruit"
            >
              {{ translateTitle("募集中") }}
            </div>
            <div class="schedule storage" v-if="productInfo.period == 2">
              {{ translateTitle("随时可存") }}
            </div>

            <span
              :class="'type-' + `${productInfo.status}`"
              v-if="productInfo.status > 3"
            >
              {{ financialStatusMaps.label[productInfo.status] }}
            </span>
          </div>
          <p class="product-date">
            {{ translateTitle("高收益，无风险，到期自动回款") }}
          </p>
        </div>
        <div style="">
          <p class="number" style="text-align:left">
            {{ Number(productInfo.annual_rate).toFixed(4) }}%
          </p>
          <p class="label">
            {{ translateTitle("年化利率") }}
          </p>
        </div>
        <div>
          <p class="number">
            {{ productInfo.lock_period }}{{ translateTitle("天") }}
          </p>
          <p class="label">
            {{ translateTitle("锁定期") }}
          </p>
        </div>
      </div>
      <div
        class="first-content-2-3"
        v-if="productInfo.product_type == 2 || productInfo.product_type == 3"
      >
        <div>
          <p>
            <img :src="productInfo.item_url" alt="" />
            <span class="first-test">{{ productInfo.product_name }}</span>
            <!-- <span class="first-type-1">稳健型</span> -->
          </p>
          <p
            class="product-date text-overflow"
            style="max-width:350px"
            v-if="productInfo.product_type == 2"
          >
            {{ translateTitle("当日存入，次日起息，滚动复利，灵活存取") }}
          </p>
          <p class="product-date" v-if="productInfo.product_type == 3">
            {{ translateTitle("产品到期") }}
            {{ productInfo.remain_end_earning_day || "--" }}
            {{ translateTitle("天") }}
          </p>
        </div>
        <div>
          <p class="number">{{ productInfo.annual_rate }}%</p>
          <p class="label" v-if="productInfo.rate_type == 1">
            {{ translateTitle("当前年化利率") }}
          </p>
          <p class="label" v-else-if="productInfo.rate_type == 2">
            {{ translateTitle("历史最高年化") }}
          </p>
        </div>
      </div>
      <div class="first-content-4" v-if="productInfo.product_type == 4">
        <div>
          <p>
            <img :src="productInfo.to_item_url" alt="" />
            <span class="first-test">{{ productInfo.product_name }}</span>
            <span
              class="first-type-1 common-btn"
              v-if="productInfo.status > 1 && productInfo.status < 4"
              >{{ translateTitle("募集中") }}</span
            >
            <span
              :class="'type-' + `${productInfo.status}`"
              v-if="productInfo.status > 3"
            >
              {{ financialStatusMapsTwo.label[productInfo.status] }}
            </span>
          </p>
          <p class="product-date">
            {{ translateTitle(icoTitle) }}
          </p>
        </div>
        <div>
          <p class="number">
            {{ Number(productInfo.to_item_id_price).toFixed(4)
            }}{{ productInfo.item_name }}
          </p>
          <p class="label V2">{{ translateTitle("兑换价格") }}</p>
        </div>
        <div>
          <p class="number" v-if="productInfo.lock_period == '--'">--%</p>
          <p class="number" v-else>
            {{ Number(productInfo.lock_period) }}{{ translateTitle("天") }}
          </p>
          <p class="label">{{ translateTitle("交付期") }}</p>
        </div>
      </div>
      <div v-if="productInfo.product_type == 4">
        <div class="second-content" v-if="productInfo.ico_buy_condition == 2">
          <div class="total-rate">
            <div class="total-num-box">
              <span class="already-num">
                {{ translateTitle("已抢") }} {{ rate }} %
              </span>

              <span class="total-num" v-if="productInfo.amount == 0">{{
                translateTitle("无上限")
              }}</span>
              <span class="total-num" v-else
                >{{ translateTitle("总额度：") }}{{ productInfo.amount }}
                {{ productInfo.item_name }}</span
              >
            </div>
            <div class="total-line-box">
              <i :style="{ width: `${rate}%` }"></i>
            </div>
          </div>
        </div>
        <div class="second-content" v-else>
          <div class="total-rate">
            <div class="total-num-box">
              <span class="already-num" v-if="productInfo.amount == 0">
                {{ translateTitle("已募集") }} {{ productInfo.total_amount }}
                <span>{{ productInfo.item_name }}</span>
              </span>
              <span class="already-num" v-else>
                {{ translateTitle("已募集") }} {{ rate }} %
              </span>
              <span class="total-num" v-if="productInfo.amount == 0">{{
                translateTitle("无上限")
              }}</span>
              <span class="total-num" v-else
                >{{ translateTitle("总额度：") }}{{ productInfo.amount }}
                {{ productInfo.item_name }}</span
              >
            </div>
            <div class="total-line-box">
              <i :style="{ width: `${rate}%` }"></i>
            </div>
          </div>
        </div>
        <div class="four-content">
          <div class="step-graph">
            <i
              :class="[
                'step-circle',
                'step-circle1',
                stepActive == 1 || stepActive == 2 || stepActive == 3
                  ? 'active'
                  : '',
              ]"
            ></i>
            <i
              :class="[
                'step-circle',
                'step-circle2',
                stepActive == 2 || stepActive == 3 ? 'active' : '',
              ]"
            ></i>
            <i
              :class="[
                'step-circle',
                'step-circle3',
                stepActive == 3 ? 'active' : '',
              ]"
            ></i>
            <div
              :class="[
                'step-line',
                'step-line1',
                stepActive == 1 || stepActive == 2 || stepActive == 3
                  ? 'active'
                  : '',
              ]"
            >
              <i :style="{ width: `${rate1 * 100}%` }"></i>
            </div>
            <div
              :class="[
                'step-line',
                'step-line2',
                stepActive == 2 || stepActive == 3 ? 'active' : '',
              ]"
            >
              <i :style="{ width: `${rate2 * 100}%` }"></i>
            </div>
          </div>

          <div class="step-text-box">
            <div class="step-block step-block1">
              <span class="step-text">{{ translateTitle("募集开始") }}</span>
              <span class="step-time">{{
                timestampToDate(productInfo.start_time)
              }}</span>
            </div>
            <div class="step-block step-block2">
              <span class="step-text">{{ translateTitle("交付开始") }}</span>
              <span class="step-time">{{
                timestampToDate(productInfo.stop_time)
              }}</span>
            </div>
            <div class="step-block step-block3">
              <span class="step-text">{{ translateTitle("交付完成") }}</span>
              <span class="step-time">{{
                timestampToDate(productInfo.end_time)
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="second-content"
        v-if="
          (productInfo.product_type == 1 || productInfo.product_type == 3) &&
            productInfo.amount != 0
        "
      >
        <div class="total-rate">
          <div class="total-num-box">
            <span class="already-num">
              {{ translateTitle("已募集") }} {{ rate }} %
            </span>
            <span class="total-num">100%</span>
          </div>
          <div class="total-line-box">
            <i :style="{ width: `${rate}%` }"></i>
          </div>
        </div>
      </div>
      <div
        class="four-content"
        v-if="productInfo.product_type == 1 || productInfo.product_type == 3"
      >
        <div class="step-graph">
          <i
            :class="[
              'step-circle',
              'step-circle1',
              stepActive == 1 || stepActive == 2 || stepActive == 3
                ? 'active'
                : '',
            ]"
          ></i>
          <i
            :class="[
              'step-circle',
              'step-circle2',
              stepActive == 2 || stepActive == 3 ? 'active' : '',
            ]"
          ></i>
          <i
            :class="[
              'step-circle',
              'step-circle3',
              stepActive == 3 ? 'active' : '',
            ]"
          ></i>
          <div
            :class="[
              'step-line',
              'step-line1',
              stepActive == 1 || stepActive == 2 || stepActive == 3
                ? 'active'
                : '',
            ]"
          >
            <i :style="{ width: `${rate1 * 100}%` }"></i>
          </div>
          <div
            :class="[
              'step-line',
              'step-line2',
              stepActive == 2 || stepActive == 3 ? 'active' : '',
            ]"
          >
            <i :style="{ width: `${rate2 * 100}%` }"></i>
          </div>
        </div>

        <div class="step-text-box">
          <div class="step-block step-block1" v-if="productInfo.period == 2">
            <span class="step-text">{{ translateTitle("存入") }}</span>
            <span
              class="step-time"
              v-if="
                productInfo.product_type == 1 || productInfo.product_type == 3
              "
              >{{ timestampToDate(productInfo.start_time) }}</span
            >
          </div>
          <div
            class="step-block step-block1"
            v-else-if="productInfo.period == 1"
          >
            <span class="step-text">{{ translateTitle("募集开始") }}</span>
            <span class="step-time">{{
              timestampToDate(productInfo.start_time)
            }}</span>
          </div>
          <div class="step-block step-block2">
            <span class="step-text">{{ translateTitle("开始计息") }}</span>
            <span class="step-time">{{
              timestampToDate(productInfo.stop_time)
            }}</span>
          </div>
          <div class="step-block step-block3">
            <span class="step-text">{{ translateTitle("产品到期") }}</span>
            <span class="step-time" v-if="productInfo.product_type == 3">{{
              timestampToDate(productInfo.quant_end_earning_time)
            }}</span>
            <span class="step-time" v-else>{{
              timestampToDate(productInfo.end_time)
            }}</span>
          </div>
        </div>
      </div>
      <div
        class="financial-message-content"
        v-if="productInfo.product_type == 3"
      >
        <p class="third-header">{{ translateTitle("项目信息") }}</p>
        <div class="third-item">
          <div class="line-item">
            <div class="pre">{{ translateTitle("项目名称") }}</div>
            <div class="next">
              {{ productInfo.product_name }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("项目类型") }}</div>
            <div class="next">
              {{ translateTitle(quantTagMaps.label[productInfo.quant_tag]) }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("收益类型") }}</div>
            <div class="next">
              {{ translateTitle(rateTypeMaps.label[productInfo.rate_type]) }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("计价单位") }}</div>
            <div class="next">{{ productInfo.item_name }}</div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("最低认购金额") }}</div>
            <div class="next">
              {{ formatNumberRgx(productInfo.min_amount) }}
              {{ productInfo.item_name }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("最小募集金额") }}</div>
            <div class="next" v-if="productInfo.amount == 0">
              {{ translateTitle("不限额") }}
            </div>
            <div class="next" v-else>
              {{ formatNumberRgx(productInfo.lowest_amount) }}
              {{ productInfo.item_name }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("最大募集金额") }}</div>
            <div class="next" v-if="productInfo.amount == 0">
              {{ translateTitle("不限额") }}
            </div>
            <div class="next" v-else>
              {{ formatNumberRgx(productInfo.amount) }}
              {{ productInfo.item_name }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("项目周期") }}</div>
            <div class="next">
              {{ productInfo.lock_period }} {{ translateTitle("天") }}
            </div>
          </div>
        </div>
      </div>
      <div class="current-message-content" v-if="productInfo.product_type == 2">
        <p class="third-header">{{ translateTitle("活期存款") }}</p>
        <div class="third-item">
          {{
            translateTitle(
              "活期存款是为用户提供的数字货币和法币的资产增值理财计划，用户存入后，即可享受资金快进快出、存取灵活的便利服务。承诺在满足本金安全和流动性需求的基础上力求实现更高的收益。"
            )
          }}
        </div>
        <div class="third-img">
          <div class="img-item">
            <img
              src="../../../../assets/img/finances/current-img-1.png"
              alt=""
            />
            <div>
              <p>
                {{ translateTitle("多样") }}
              </p>
              <p>{{ translateTitle("支持多币种 多样理财产品") }}</p>
            </div>
          </div>
          <div class="img-item">
            <img
              src="../../../../assets/img/finances/current-img-2.png"
              alt=""
            />
            <div>
              <p>
                {{ translateTitle("专业") }}
              </p>
              <p>{{ translateTitle("采用全球领先 资产风控体系") }}</p>
            </div>
          </div>
          <div class="img-item">
            <img
              src="../../../../assets/img/finances/current-img-3.png"
              alt=""
            />
            <div>
              <p>
                {{ translateTitle("安全") }}
              </p>
              <p>{{ translateTitle("离线存储多重 授签安全透明") }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed-content-message" v-if="productInfo.product_type == 1">
        <p class="third-header">{{ translateTitle("定期存款") }}</p>
        <div class="third-item">
          {{
            translateTitle(
              "定期存款是为用户提供的数字货币和法币的资产增值理财计划，用户存入后，即可享受理财期限固定、到期自动回款的理财便利服务。承诺本金和收益安全。"
            )
          }}
        </div>
        <div class="third-img">
          <div class="img-item">
            <img
              src="../../../../assets/img/finances/current-img-1.png"
              alt=""
            />
            <div>
              <p>
                {{ translateTitle("多样") }}
              </p>
              <p>{{ translateTitle("支持多币种 多样理财产品") }}</p>
            </div>
          </div>
          <div class="img-item">
            <img
              src="../../../../assets/img/finances/current-img-2.png"
              alt=""
            />
            <div>
              <p>
                {{ translateTitle("专业") }}
              </p>
              <p>{{ translateTitle("采用全球领先 资产风控体系") }}</p>
            </div>
          </div>
          <div class="img-item">
            <img
              src="../../../../assets/img/finances/current-img-3.png"
              alt=""
            />
            <div>
              <p>
                {{ translateTitle("安全") }}
              </p>
              <p>{{ translateTitle("离线存储多重 授签安全透明") }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="hxpool-message-content" v-if="productInfo.product_type == 4">
        <p class="third-header">
          <span>
            {{ translateTitle(headerText) }}
          </span>
          <span> </span>
          <!-- <i
            class="iconfont iconwenhao"
            style="cursor: pointer;"
            @click="onShowInfo"
          ></i> -->
          <span
            @click="onMessage(productInfo.detail.ico_url)"
            style="float: right;font-size: 14px;color: #9aa1b1;cursor: pointer;font-weight: normal;"
          >
            {{ translateTitle("认识") }} {{ productInfo.to_item_name }}
            <i
              class="iconfont iconwenhao"
              style="cursor: pointer;vertical-align: inherit"
            ></i>
          </span>
        </p>
        <div class="third-item">
          <div class="line-item">
            <div class="pre">{{ translateTitle("兑换总额度") }}</div>
            <div class="next">
              {{ productInfo.public_raise_amount }}
              {{ productInfo.to_item_name }}
            </div>
          </div>
          <div
            class="line-item"
            v-if="
              productInfo.detail.join_rule != '' &&
                productInfo.detail.join_rule != undefined
            "
          >
            <div class="pre">{{ translateTitle("参与条件") }}</div>
            <div class="next">
              {{ productInfo.detail.join_rule }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("存入时间") }}</div>
            <div class="next" v-if="productInfo.period == 1">
              {{ translateTitle("募集期内均可存入") }}
            </div>
            <div class="next" v-else>
              {{ translateTitle("每日00:00-23:59:59") }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("交付时间") }}</div>
            <div class="next">
              {{ productInfo.lock_period }} {{ translateTitle("天") }}
            </div>
          </div>
          <div
            class="line-item"
            v-if="JSON.stringify(productInfo.detail) != '[]'"
          >
            <div class="pre">{{ translateTitle("参与类型") }}</div>
            <div class="next">
              {{ productInfo.detail.product_code }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("兑换价格") }}</div>
            <div class="next">
              1 {{ productInfo.to_item_name }}
              ≈
              {{ productInfo.to_item_id_price }}
              {{ productInfo.item_name }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("单笔限额") }}</div>
            <div class="next">
              {{ formatNumberRgx(productInfo.min_amount) }}-{{
                formatNumberRgx(productInfo.max_amount)
              }}
              {{ productInfo.item_name }}
            </div>
          </div>
          <!-- <div class="line-item">
            <div class="pre">项目周期</div>
            <div class="next">{{ productInfo.lock_period }} 天</div>
          </div> -->
        </div>
      </div>
      <div
        class="current-kline-content"
        v-show="productInfo.product_type == 2 && productInfo.rate_type != 2"
      >
        <div class="title V2">{{ translateTitle("每日利率") }}</div>
        <div class="chart-title">
          <div>
            <span>{{ translateTitle("日期：") }}{{ showName }}</span>
          </div>
          <div style="width:fit-content;;text-align:right">
            <span
              >{{ translateTitle("年化利率") }}
              <i class="positive DINPro"> {{ showData || "--" }}%</i>
            </span>
          </div>
        </div>
        <v-chart
          :options="currentKlineOption"
          class="kline-chart"
          ref="currentKlineOption"
        />
      </div>
      <div
        class="muti-kline-content"
        v-show="
          (productInfo.product_type == 3 || productInfo.product_type == 2) &&
            productInfo.rate_type == 2
        "
      >
        <ul class="tab-list">
          <li
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ active: currTab == item.value }"
            @click="onTabClick(item)"
          >
            {{ translateTitle(item.label) }}
          </li>
        </ul>
        <v-chart
          :options="mutiKlineOption"
          class="kline-chart"
          ref="mutiKlineOption"
          v-show="this.currTab != 3"
        />
        <v-chart
          :options="mutiKlineOptionHistory"
          class="kline-chart"
          ref="mutiKlineOptionHistory"
          v-show="this.currTab == 3"
        />
      </div>
      <div class="common-dialog-footer">
        <span
          class="dialog-disable-btn"
          style="width:320px"
          v-if="productInfo.status > 3 && productInfo.status < 5"
          >{{ translateTitle("募集结束") }}</span
        >
        <span
          class="dialog-status5-btn"
          style="width:320px"
          v-else-if="productInfo.product_type == 4 && productInfo.status == 5"
          >{{ translateTitle("交付中") }}</span
        >
        <span
          class="dialog-status5-btn"
          style="width:320px"
          v-else-if="productInfo.product_type != 4 && productInfo.status == 5"
          >{{ translateTitle("计息中") }}</span
        >
        <span
          class="dialog-status5-btn"
          style="width:320px"
          v-else-if="productInfo.status == 6"
          >{{ translateTitle("本息发放中") }}</span
        >
        <span
          class="dialog-disable-btn"
          style="width:320px"
          v-else-if="productInfo.product_type == 4 && productInfo.status == 7"
          >{{ translateTitle("交付完成") }}</span
        >
        <span
          class="dialog-disable-btn"
          style="width:320px"
          v-else-if="productInfo.product_type != 4 && productInfo.status == 7"
          >{{ translateTitle("本息已到账") }}</span
        >
        <span
          class="dialog-status5-btn"
          style="width:320px"
          v-else-if="productInfo.status == 1"
          >{{ translateTitle("募集预热中") }}</span
        >
        <span
          class="dialog-disable-btn"
          style="width:320px"
          v-else-if="productInfo.status == 8"
          >{{ translateTitle("募集失败") }}</span
        >
        <span
          class="dialog-disable-btn"
          style="width:320px"
          v-else-if="
            productInfo.status == 2 &&
              productInfo.start_time != 0 &&
              productInfo.remain_time > 1000
          "
        >
          <Countdown
            :time="productInfo.remain_time / 1000"
            @on-end="onCountDownEnd()"
            format="hh:mm:ss"
          >
            <template slot-scope="{ time }" class="DINPro">
              <div class="DINPro">
                {{ time }} {{ translateTitle("后开幕") }}
              </div></template
            >
          </Countdown>
        </span>

        <span class="dialog-confirm-btn" v-else @click="onOK">{{
          translateTitle("立即存入")
        }}</span>
      </div>
    </div>
    <div class="right-item" v-if="pageLoading"></div>
    <div class="right-item" v-else>
      <i
        class="el-dialog__close el-icon el-icon-close icon-close"
        @click="onDialogClose"
      ></i>
      <div
        class="item-content"
        v-if="productInfo.product_type == 3 || productInfo.product_type == 1"
      >
        <div class="title">{{ translateTitle("存入规则") }}</div>
        <div class="tip-box">
          <div class="line-item">
            <div class="pre">{{ translateTitle("存入时间") }}</div>
            <div class="next" v-if="productInfo.period == 1">
              {{ translateTitle("募集期内均可存入") }}
            </div>
            <div class="next" v-else>
              {{ translateTitle("每日00:00-23:59:59") }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("存入期限") }}</div>
            <div class="next">
              {{ productInfo.lock_period }} {{ translateTitle("天") }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("单笔限额") }}</div>
            <div class="next">
              {{ formatNumberRgx(productInfo.min_amount) }}-{{
                formatNumberRgx(productInfo.max_amount)
              }}
              {{ productInfo.item_name }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="item-content"
        v-if="productInfo.product_type == 3 || productInfo.product_type == 1"
      >
        <div class="title">{{ translateTitle("取出规则") }}</div>
        <div class="tip-box">
          <div class="line-item">
            <div class="pre">
              {{ translateTitle("存入后不可撒销，到期前不可取出") }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">
              {{ translateTitle("募集开始时间：")
              }}{{ timestampToDate(productInfo.start_time) }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">
              {{ translateTitle("募集结束与起息时间：")
              }}{{ timestampToDate(productInfo.stop_time) }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">
              {{ translateTitle("利息发放时间：")
              }}{{ timestampToDate(productInfo.end_time) }}
            </div>
          </div>
        </div>
      </div>
      <div class="item-content" v-if="productInfo.product_type == 2">
        <div class="title">{{ translateTitle("存取规则") }}</div>
        <div class="tip-box">
          <div class="line-item">
            <div class="pre">{{ translateTitle("单笔限额") }}</div>
            <div class="next">
              {{ formatNumberRgx(productInfo.min_amount) }}-{{
                formatNumberRgx(productInfo.max_amount)
              }}
              {{ productInfo.item_name }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("取出规则") }}</div>
            <div class="next">{{ translateTitle("随时取出，实时到账") }}</div>
          </div>
        </div>
      </div>
      <div class="item-content" v-if="productInfo.product_type == 4">
        <div class="title">{{ translateTitle("兑换规则") }}</div>
        <div class="tip-box">
          <div class="line-item" v-if="productInfo.ico_buy_condition == 2">
            <div class="pre">{{ translateTitle("购买后禁止取出") }}</div>
          </div>
          <div class="line-item-v2" v-else>
            <div class="pre">
              {{
                translateTitle(
                  "购买后禁止取出，不限制个人投放金额，投放的越多，最后被分配到的额度比例越大。"
                )
              }}
            </div>
            <div class="pre">
              {{ translateTitle("您可按照以下公式来计算应分配代币数量：") }}
            </div>
            <div class="pre">
              {{
                translateTitle(
                  "某用户应分配代币数量 = 该用户投入的USDT数量 / 所有用户投入的USDT数量 * 本期【超募池】售卖的代币总量。"
                )
              }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">
              {{ translateTitle("募集结束：")
              }}{{ timestampToDate(productInfo.stop_time) }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">
              <span v-if="productInfo.ico_buy_condition == 1">
                {{ productInfo.to_item_name }}
                {{ translateTitle("代币转换与开始交付时间：") }}
              </span>
              <span v-else>
                {{ translateTitle("开始交付时间:") }}
              </span>
              {{ timestampToDate(productInfo.stop_time) }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">
              {{ translateTitle("交付完成时间:") }}
              {{ timestampToDate(productInfo.end_time) }}
            </div>
          </div>
        </div>
        <div class="tip-box" v-if="productInfo.detail.buy_rule != ''">
          <div class="line-item">
            <div class="pre">
              <span v-html="productInfo.detail.buy_rule"> </span>
            </div>
          </div>
        </div>
        <div class="tip-box" v-if="productInfo.detail.risk_tip != ''">
          <div class="line-item">
            <div class="pre">
              <span v-html="productInfo.detail.risk_tip"> </span>
            </div>
          </div>
        </div>
      </div>
      <div class="item-content" v-if="productInfo.product_type != 4">
        <div class="title">{{ translateTitle("收益规则") }}</div>
        <div class="tip-box">
          <div class="line-item" v-if="productInfo.product_type == 1">
            <div class="pre">{{ translateTitle("收益计算") }}</div>
            <div class="next">
              <span>{{
                translateTitle("到期收益=存入金额*年化利率*锁定期/365")
              }}</span>
            </div>
          </div>
          <div class="line-item" v-else-if="productInfo.product_type == 2">
            <div class="pre">{{ translateTitle("收益计算") }}</div>
            <div class="next">
              <span>{{
                translateTitle("当日收益=日末资产*日末年化利率/365")
              }}</span>
            </div>
          </div>
          <div class="line-item" v-else>
            <div class="pre">{{ translateTitle("收益计算") }}</div>
            <div class="next">
              <span v-if="productInfo.rate_type == 2">{{
                translateTitle("预计收益")
              }}</span>
              <span v-else>{{ translateTitle("实际到账收益") }}</span>
              {{ translateTitle("=当期持仓盈亏-项目运作人分润") }}
            </div>
          </div>
          <div class="line-item" v-if="productInfo.product_type == 2">
            <div class="pre">{{ translateTitle("发放时间") }}</div>
            <div class="next">
              {{ translateTitle("每日16点(UTC+8)发放前一日收益") }}
            </div>
          </div>
          <div
            class="line-item"
            v-if="
              productInfo.product_type == 1 || productInfo.product_type == 3
            "
          >
            <div class="pre">{{ translateTitle("收益规则") }}</div>
            <div class="next" v-if="productInfo.period == 2">
              {{ translateTitle("当日存入，次日起息(UTC+8)") }}
            </div>
            <div class="next" v-else>
              {{ translateTitle("募集结束即开始计息") }}
            </div>
          </div>
          <div class="line-item">
            <div class="pre">{{ translateTitle("到账方式") }}</div>
            <div class="next" v-if="productInfo.product_type == 2">
              {{ translateTitle("每日收益自动复存，享受滚动复利") }}
            </div>
            <div class="next" v-else>
              {{ translateTitle("到期自动发放本息至余额") }}
            </div>
          </div>
          <div class="line-item" v-if="productInfo.product_type == 2">
            <div class="pre">{{ translateTitle("每日利息") }}</div>
            <div class="next" v-if="productInfo.rate_type == 1">
              {{ translateTitle("根据市场情况，按实时利率计算") }}
            </div>
            <div class="next" v-else>
              {{
                translateTitle(
                  "根据市场情况，利率实时变化，日末计算当日收益时按实时利率计算"
                )
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="pie-component" v-if="productInfo.product_type == 3">
        <div class="title">{{ translateTitle("利润分配") }}</div>
        <div class="sub-title">
          {{
            translateTitle("项目回报在份额持有人及项目运作人之间以一定比例分配")
          }}
        </div>
        <div class="chart-container">
          <v-chart :options="options" class="data-chart" ref="dataEchart" />
          <div class="legend">
            <div class="line-item no">
              <i class="fir"></i>
              <span>
                {{ translateTitle("项目运作人") }}
                {{ productInfo.trader_profit * 100 }}%
              </span>
            </div>
            <div class="line-item">
              <i class="sec"></i>
              <span>
                {{ translateTitle("份额持有人") }}
                {{ productInfo.user_profit * 100 }}%
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import { mapGetters } from "vuex";
import ECharts from "vue-echarts";
import * as echarts from "echarts";
import { timestampToDate, timestampToDay } from "@/assets/js/formatDate";
import { translateTitle } from "@/assets/js/i18n";
import { floorNumber, ceilNumber } from "@/assets/js/digital";
import {
  rateTypeMaps,
  quantTagMaps,
  financialStatusMaps,
  financialStatusMapsTwo,
} from "@/assets/js/keymap.js";
import { showMessage } from "@/components/dialog/message";
import Countdown from "@choujiaojiao/vue2-countdown";
import { apiGetProductInfo } from "@/model/finance/finance";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  dialogVisible: true,
  components: {
    Countdown,
    "v-chart": ECharts,
  },
  props: {
    productId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      showName: "",
      headerText: "汇信池规则",
      showData: "",
      rateTypeMaps,
      quantTagMaps,
      financialStatusMaps,
      financialStatusMapsTwo,
      icoTitle: "汇信池：早一步发现价值，寻找下一个比特币",
      pageLoading: true,
      stepActive: 1,
      rate: 1,
      rate1: 1,
      rate2: 1,
      currTab: 1,
      tabList: [
        {
          value: "1",
          label: "7日年化",
        },
        {
          value: "2",
          label: "30日年化",
        },
        {
          value: "3",
          label: "历史净值",
        },
      ],
      pieData: [
        { value: 70, name: this.translateTitle("项目运作人") },
        { value: 30, name: this.translateTitle("份额持有人") },
      ],
      options: {
        color: ["#3a7dff", "#f04a5a"],
        tooltip: {
          show: false,
        },
        series: [
          {
            name: "利润分配",
            smooth: true,
            type: "pie",
            radius: ["30%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "12",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 30, name: this.translateTitle("份额持有人") },
              { value: 70, name: this.translateTitle("项目运作人") },
            ],
          },
        ],
      },
      mutiKlineOptionHistory: {
        color: ["#f04a5a"],
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: val => {
            let result = "";
            this.showName = val[0].name;
            this.showData = val[0].data;
            result = `
          ${val[0].name}<br />
          ${val[0].data}`;
            return result;
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "20%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: val => {
                return val.toFixed(3);
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            stack: "总量",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255, 74, 120,1)",
                },
                {
                  offset: 1,
                  color: "rgba(255, 134, 165, 0.09)",
                },
              ]),
            },
            fillOpacity: 0.1,
            emphasis: {
              focus: "series",
            },
            data: [],
          },
        ],
      },
      mutiKlineOption: {
        color: ["#f04a5a"],
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: val => {
            let result = "";
            if (this.currTab == 3) {
              this.showName = val[0].name;
              this.showData = val[0].data;
              result = `
          ${val[0].name}<br />
          ${val[0].data}`;
              return result;
            } else {
              this.showName = val[0].name;
              this.showData = val[0].data * 100;
              result = `
          ${val[0].name}<br />
          ${(val[0].data * 100).toFixed(2)}%`;
              return result;
            }

            //   this.showName = val[0].name;
            //   this.showData = val[0].data * 100;
            //   const result = `
            // ${val[0].name}<br />
            // ${val[0].data * 100}%
            return result;
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "20%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: val => {
                if (this.currTab == 3) {
                  return val.toFixed(3);
                } else {
                  return (val * 100).toFixed(0) + "%";
                }
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            stack: "总量",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255, 74, 120,1)",
                },
                {
                  offset: 1,
                  color: "rgba(255, 134, 165, 0.09)",
                },
              ]),
            },
            fillOpacity: 0.1,
            emphasis: {
              focus: "series",
            },
            data: [],
          },
        ],
      },
      incRateList: [],
      rateList: [],
      rateList30: [],
      klineXList: [],
      klineYList: [],
      currentKlineOption: {
        color: ["#f04a5a"],
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: val => {
            this.showName = val[0].name;
            this.showData = (val[0].data * 100).toFixed(2);
            const result = `
          ${val[0].name}<br />
          ${(val[0].data * 100).toFixed(2)}%
        `;
            return result;
          },
        },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "3%",
          top: "10%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.klineXList,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: val => {
                return (val * 100).toFixed(2) + "%";
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            stack: "总量",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255, 74, 120,1)",
                },
                {
                  offset: 1,
                  color: "rgba(255, 134, 165, 0.09)",
                },
              ]),
            },
            fillOpacity: 0.1,
            emphasis: {
              focus: "series",
            },
            data: this.klineYList,
          },
        ],
      },
      productInfo: {
        product_id: 33,
        product_name: "--",
        item_id: 10007,
        channel_id: 101,
        item_type: 1,
        to_item_id: 10069,
        to_item_id_price: "--",
        product_type: 3,
        rate_type: 0,
        quant_tag: 0,
        annual_rate: "--",
        min_fluctuation_annual_rate: "0.0000",
        max_fluctuation_annual_rate: "0.0000",
        day_rate: 0,
        current_rate: "0.0000",
        period: 1,
        start_time: 1620370800,
        stop_time: 1620975600,
        lock_period: 7,
        amount: 0,
        lowest_amount: 0,
        total_amount: "--",
        min_amount: "--",
        max_amount: "--",
        per_min_num: 1,
        per_max_num: 200,
        number_precision: 2,
        price_precision: 2,
        amount_precision: 2,
        trader_profit: 0,
        status: 7,
        union_product_id: 0,
        union_order_by: 1,
        is_union_master: 0,
        public_raise_amount: "--",
        ico_buy_condition: 1,
        ico_user_register_time: 0,
        is_hide: 0,
        is_show_list: 1,
        is_recommand: 1,
        is_delete: 0,
        access_type: 1,
        order_by: 1,
        i18n_lang: "static_finances_product_product_name_330",
        create_time: 1620324487,
        update_time: 1620975363,
        detail: {
          product_id: 33,
          product_intro: "",
          inout_rule: "",
          earnings_rule: "",
          product_code: "--",
          strategy: "",
          ico_url:
            "https://top6688.zendesk.com/hc/zh-cn/articles/4402147339921-%E8%AE%A4%E8%AF%86-O3",
          ico_eg: "",
          buy_rule: "--",
          risk_tip: "--",
          join_rule: "",
          i18n_lang:
            "ico_url_31,ico_eg_33,buy_rule_31,risk_tip_31,join_rule_0,product_code_33",
        },
        item_name: "USDT",
        item_url: require("../../../../assets/img/holder-coin-ico.png"),
        to_item_url: require("../../../../assets/img/holder-coin-ico.png"),
        to_item_name: "O3",
        end_time: 1621580400,
        user_profit: 1,
        show_profit: 0,
      },
    };
  },
  created() {
    this.headerText = "Launchpad规则";
    this.icoTitle = "打新：早一步发现价值，寻找下一个比特币";
    this.getFinancesProductInfo();
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
  },
  methods: {
    routeSplicing,
    timestampToDate,
    timestampToDay,
    translateTitle,
    onCountDownEnd() {
      this.getFinancesProductInfo();
    },
    onShowInfo() {
      window.open(
        "https://top6688.zendesk.com/hc/zh-cn/articles/360019970998-%E6%B1%87%E4%BF%A1%E6%B1%A0"
      );
    },
    onMessage(ico_url) {
      window.open(ico_url);
    },
    formatNumberRgx(num) {
      var parts = num.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    onOK() {
      if (!this.userInfo) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: { redirect: this.$route.fullPath },
        });
        return;
      }
      this.$emit("onConfirm");
    },
    onTabClick(item) {
      this.currTab = item.value;
      this.klineXList = [];
      this.klineYList = [];
      if (item.value == 1) {
        this.rateList.forEach(ele => {
          this.klineXList.push(this.timestampToDay(ele.date));
          this.klineYList.push(ele.rate);
        });
      }
      if (item.value == 2) {
        this.rateList30.forEach(ele => {
          this.klineXList.push(this.timestampToDay(ele.date));
          this.klineYList.push(ele.rate);
        });
      } else if (item.value == 3) {
        this.incRateList.forEach(ele => {
          this.klineXList.push(this.timestampToDay(ele.date));
          this.klineYList.push(ele.rate);
        });
        let maxValue = Math.max(...this.klineYList);
        let minValue = Math.min(...this.klineYList);
        let interval = (maxValue - minValue) / 2;
        this.mutiKlineOptionHistory.yAxis[0].min = minValue - interval / 2;
        this.mutiKlineOptionHistory.yAxis[0].max = maxValue + interval / 2;
        this.mutiKlineOptionHistory.yAxis[0].interval = interval;
        this.mutiKlineOptionHistory.yAxis[0].splitNumber = 3;
        this.mutiKlineOptionHistory.xAxis[0].data = this.klineXList;
        this.mutiKlineOptionHistory.series[0].data = this.klineYList;
      }

      this.mutiKlineOption.xAxis[0].data = this.klineXList;
      this.mutiKlineOption.series[0].data = this.klineYList;

      if (item.value == 3) {
        this.$nextTick(() => {
          this.$refs.mutiKlineOptionHistory.setOption(
            this.mutiKlineOptionHistory
          );
        });
      } else {
        this.$nextTick(() => {
          this.$refs.mutiKlineOption.setOption(this.mutiKlineOption);
        });
      }
    },
    onDialogClose() {
      this.$emit("onDialogClose");
    },
    getFinancesProductInfo() {
      let params = {
        product_id: this.productId,
      };
      this.pageLoading = true;
      apiGetProductInfo(params).then(res => {
        if (res.status == 200) {
          this.productInfo = res.data.productInfo;
          this.$emit("onProductInfoChange", this.productInfo);
          this.xList = res.data.rateList;
          this.rateList = res.data.rateList;
          this.rateList30 = res.data.rateList30;
          this.incRateList = res.data.incRateList;
          this.klineXList = [];
          this.klineYList = [];
          this.rateList.forEach(ele => {
            this.klineXList.push(this.timestampToDay(ele.date));
            this.klineYList.push(ele.rate);
          });

          const now_time = (new Date().getTime() / 1000).toFixed();
          let tempRate =
            (this.productInfo.total_amount / this.productInfo.amount) * 100;
          this.rate = ceilNumber(tempRate, 2);
          // (
          //   (this.productInfo.total_amount / this.productInfo.amount) *
          //   100
          // ).toFixed(2);
          this.rate1 =
            (now_time - this.productInfo.start_time) /
            (this.productInfo.stop_time - this.productInfo.start_time);
          this.rate1 = this.rate1 > 1 ? 1 : this.rate1;
          this.rate2 =
            (now_time - this.productInfo.stop_time) /
            (this.productInfo.end_time - this.productInfo.stop_time);
          this.rate2 = this.rate2 > 1 ? 1 : this.rate2;

          if (this.productInfo.status == 3) {
            this.stepActive = 1;
          } else if (
            this.productInfo.status >= 4 &&
            this.productInfo.status < 7
          ) {
            this.stepActive = 2;
          } else if (this.productInfo.status == 7) {
            this.stepActive = 3;
          }

          if (this.productInfo.product_type != 2) {
            const now = Date.parse(new Date());
            if (this.productInfo.start_time != 0) {
              this.productInfo.remain_time =
                this.productInfo.start_time * 1000 - now;
            } else {
              this.productInfo.timeCount = 0;
            }
          }

          if (this.productInfo.product_type == 2) {
            this.currentKlineOption.xAxis[0].data = this.klineXList;
            this.currentKlineOption.series[0].data = this.klineYList;

            this.mutiKlineOption.xAxis[0].data = this.klineXList;
            this.mutiKlineOption.series[0].data = this.klineYList;
            this.showName = timestampToDay(
              this.xList[this.xList.length - 1].date
            );

            this.showData = this.productInfo.annual_rate.toFixed(2);
          } else if (this.productInfo.product_type == 3) {
            this.mutiKlineOption.xAxis[0].data = this.klineXList;
            this.mutiKlineOption.series[0].data = this.klineYList;
          }

          this.options.series[0].data[0].value = this.productInfo.user_profit;
          this.options.series[0].data[1].value = this.productInfo.trader_profit;
        } else {
          showMessage({
            message: res.msg,
            type: "error",
          });
        }
        this.pageLoading = false;
        this.$nextTick(() => {
          if (this.productInfo.product_type == 2) {
            this.$refs.currentKlineOption.setOption(this.currentKlineOption);
            this.$refs.mutiKlineOption.setOption(this.mutiKlineOption);
          } else if (this.productInfo.product_type == 3) {
            this.$refs.mutiKlineOption.setOption(this.mutiKlineOption);
            this.$refs.dataEchart.setOption(this.options);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
.finance-dialog-content {
  display: flex;
  justify-content: space-between;

  .left-item {
    min-height: 483px;
    width: 580px;
    background: white;
    padding: 30px;
    border-radius: 8px 0 0 8px;
    .first-content-1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      overflow: hidden;
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
        margin-right: 10px;
      }
      .first-test {
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #354052;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 180px;
        display: inline-block;
        vertical-align: middle;
      }
      .type-progress,
      .type-1,
      .type-6 {
        width: 102px;
        height: 20px;
        margin: 3px 0px 36px 7px;
        padding: 2.2px 5.1px 1.8px 5.6px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-image: linear-gradient(to right, #f78700 0%, #ffb152 100%);
        font-size: 12px;
        color: white;
      }
      .type-8,
      .type-4,
      .type-7 {
        width: 58px;
        height: 20px;
        margin: 3px 0px 36px 7px;
        padding: 2.2px 5.1px 1.8px 5.6px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #c2c7d0;
        font-size: 12px;
        color: white;
      }
      .type-5 {
        width: 58px;
        height: 20px;
        margin: 3px 5px 36px 4px;
        padding: 2.2px 5.1px 1.8px 5.6px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-image: linear-gradient(to right, #f7ad00 0%, #ffd452 100%);
        font-size: 12px;
        color: white;
      }
      .schedule {
        height: 20px;
        line-height: 20px;
        padding: 0px 5px;
        vertical-align: middle;
        color: white;
        font-size: 12px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: inline-block;
        margin-right: 10px;
        // background-image: linear-gradient(266deg, #579fff 39%, #0068f3 120%);
        &.accruing {
          background: rgb(196, 196, 198);
        }
        &.warming {
          background-image: linear-gradient(to right, #f78700 0%, #ffb152 100%);
        }
        &.recruit {
          background-image: linear-gradient(259deg, #ff486d 20%, #c61b3e 140%);
        }
        &.storage {
          background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
        }
        &.issuing {
          background: rgb(196, 196, 198);
        }
      }
      .product-date {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-top: 5px;
        // margin-left: 38px;
      }
      .number {
        font-size: 20px;
        font-weight: bold;
        text-align: right;
        color: #f04a5a;
      }
      .label {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-top: 5px;
        text-align: right;
      }
    }
    .first-content-2-3 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
        margin-right: 10px;
      }
      .first-test {
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #354052;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 350px;
        display: inline-block;
        vertical-align: middle;
      }
      .first-type-1 {
        height: 20px;
        margin: 2px 0 6px 0px;
        padding: 2px 5px;
        color: white;
        font-size: 12px;
        border-radius: 4.4px;
        vertical-align: text-top;
        background-image: linear-gradient(266deg, #579fff 39%, #0068f3 120%);
      }
      .product-date {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-left: 38px;
      }
      .number {
        font-size: 20px;
        font-weight: bold;
        text-align: right;
        color: #f04a5a;
      }
      .label {
        font-size: 14px;
        color: #354052;
        height: 20px;
        text-align: right;
      }
    }
    .first-content-4 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
        margin-right: 10px;
      }
      .type-1,
      .type-6 {
        width: 102px;
        height: 20px;
        margin: 3px 0px 36px 7px;
        padding: 2.2px 5.1px 1.8px 5.6px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-image: linear-gradient(to right, #f78700 0%, #ffb152 100%);
        font-size: 12px;
        color: white;
      }
      .type-8,
      .type-4,
      .type-7 {
        width: 58px;
        height: 20px;
        margin: 3px 0px 36px 7px;
        padding: 2.2px 5.1px 1.8px 5.6px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #c2c7d0;
        font-size: 12px;
        color: white;
      }
      .type-5 {
        width: 58px;
        height: 20px;
        margin: 3px 5px 36px 4px;
        padding: 2.2px 5.1px 1.8px 5.6px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-image: linear-gradient(to right, #f78700 0%, #ffb152 100%);
        font-size: 12px;
        color: white;
      }
      .first-test {
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #354052;
        max-width: 200px;
        overflow: hidden;
        vertical-align: middle;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;
      }
      .first-type-1 {
        height: 20px;
        line-height: 20px;
        margin: 2px 0 6px 0px;
        padding: 2px 5px;
        color: white;
        font-size: 12px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        vertical-align: middle;
      }
      .product-date {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-top: 5px;
        margin-left: 38px;
        max-width: 260px;
      }
      .number {
        font-size: 20px;
        font-weight: bold;
        text-align: right;
        color: #f04a5a;
      }
      .label {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-top: 5px;
        text-align: right;
        &.V2 {
          text-align: left;
        }
      }
    }
    .second-content {
      overflow: hidden;
      margin-top: 10px;
      width: 100%;
      height: 50px;
      .total-rate {
        width: px2rem(590);
        margin: px2rem(28) auto 0;
        .total-num-box {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          height: 15px;
          line-height: 15px;
          font-weight: 500;
          .total-num {
            color: #9fa2aa;
          }
          .already-num {
            color: #15d39a;
          }
        }
        .total-line-box {
          width: 100%;
          height: 6px;
          border-radius: 5px;
          background-color: #e2e2e4;
          margin-top: 8px;
          i {
            display: block;
            height: 100%;
            border-radius: 5px;
            background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
            width: 60%;
          }
        }
      }
    }
    .financial-message-content {
      margin-bottom: 20px;
      border-radius: 4px;
      .third-header {
        user-select: none;
        font-size: 16px;
        font-weight: 500;
        color: #354052;
        margin-bottom: 10px;
      }
      .third-item {
        padding: 10px 20px 10px 20px;
        border-radius: 4px;
        background-color: #f3f7fc;
        .line-item {
          display: flex;
          justify-content: space-between;
          color: #9aa1b1;
          font-size: 14px;
          line-height: 1.8;
          .next {
            max-width: 278px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .four-content {
      margin-bottom: 20px;
      .step-graph {
        position: relative;
        height: 18px;
        .step-circle {
          position: absolute;
          width: 18px;
          height: 18px;
          top: 0;
          &.step-circle1 {
            background: url("~@/assets/img/financial-detail/icon-3@3x.png")
              no-repeat;
            background-size: 100%;
            left: 0;
          }
          &.step-circle2 {
            left: 50%;
            margin-left: -9px;
            background: url("~@/assets/img/financial-detail/icon-3@3x.png")
              no-repeat;
            background-size: 100%;
          }
          &.step-circle3 {
            right: 0;
            background: url("~@/assets/img/financial-detail/icon-3@3x.png")
              no-repeat;
            background-size: 100%;
          }
          &.active {
            background: url("~@/assets/img/financial-detail/icon-2@3x.png")
              no-repeat;
            background-size: 100%;
          }
        }
        .step-line {
          position: absolute;
          width: 232px;
          height: 2px;
          border-radius: 2px;
          background-color: #e2e2e4;
          top: 8px;
          overflow: hidden;
          i {
            display: block;
            height: 100%;
            position: absolute;
            left: 0;
            background: #e2e2e4;
          }
          &.active {
            // background-color: #15d39a;
            i {
              background-color: #15d39a;
            }
          }
          &.step-line1 {
            left: 18px;
          }
          &.step-line2 {
            right: 18px;
          }
        }
      }
      .step-text-box {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .step-block {
          font-size: 12px;
          width: 100%;
          white-space: nowrap;
          .step-text {
            display: block;
            color: #354052;
            height: 15px;
            line-height: 15px;
          }
          .step-time {
            display: block;
            color: #9fa2aa;
            height: 15px;
            line-height: 15px;
            margin-top: 2px;
          }
          &.step-block1 {
            text-align: left;
          }
          &.step-block2 {
            text-align: center;
          }
          &.step-block3 {
            text-align: right;
          }
        }
      }
    }
    .current-message-content {
      margin: 20px 0;
      border-radius: 4px;
      .third-header {
        user-select: none;
        font-size: 16px;
        font-weight: 500;
        color: #354052;

        margin-bottom: 10px;
      }
      .third-item {
        background-color: #f3f7fc;
        padding: 10px 20px 10px 20px;
        border-radius: 4px;
        color: #9aa1b1;
        line-height: 1.57;
        font-weight: normal;
      }
      .third-img {
        background-color: #f3f7fc;
        display: flex;
        padding: 15px 15px 20px;
        justify-content: space-around;
        .img-item {
          display: flex;
          justify-content: space-between;
          img {
            width: 50px;
            height: 50px;
            margin-right: 5px;
          }
          p {
            width: 85px;
            color: #9aa1b1;
            &:first-child {
              color: #354052;
              font-weight: 500;
            }
          }
        }
      }
    }
    .fixed-content-message {
      margin: 20px 0;
      border-radius: 4px;
      .third-header {
        user-select: none;
        font-size: 16px;
        font-weight: 500;
        color: #354052;

        margin-bottom: 10px;
      }
      .third-item {
        background-color: #f3f7fc;
        padding: 10px 20px 10px 20px;
        border-radius: 4px;
        color: #9aa1b1;
        line-height: 1.57;
        font-weight: normal;
      }
      .third-img {
        background-color: #f3f7fc;
        display: flex;
        padding: 15px 15px 20px;
        justify-content: space-around;
        .img-item {
          display: flex;
          justify-content: space-between;
          img {
            width: 50px;
            height: 50px;
            margin-right: 5px;
          }
          p {
            width: 85px;
            color: #9aa1b1;
            &:first-child {
              color: #354052;
              font-weight: 500;
            }
          }
        }
      }
    }
    .hxpool-message-content {
      margin-bottom: 20px;
      .third-header {
        user-select: none;
        font-size: 16px;
        font-weight: 500;
        color: #354052;
        margin-bottom: 10px;
      }
      .third-item {
        padding: 10px 20px 10px 20px;
        border-radius: 4px;
        background-color: #f3f7fc;
        .line-item {
          display: flex;
          justify-content: space-between;
          color: #9aa1b1;
          font-size: 14px;
          line-height: 1.8;
          .next {
            max-width: 278px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .current-kline-content {
      margin: 10px auto 0;
      border-radius: 6px;
      background-color: #ffffff;
      .title {
        user-select: none;
        width: fit-content;
        padding: 0 15px 0 0;
        height: 22px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: #354052;
        justify-content: center;
        margin-bottom: 15px;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
      .chart-title {
        display: flex;
        line-height: 22px;
        padding: 5px 20px;
        font-size: 14px;
        color: #9aa1b1;
        justify-content: space-between;
        border-radius: 4px;
        background-color: #f3f7fc;
        > div {
          .pre {
            color: #9fa2aa;
          }
          > span {
            display: block;
          }
          &:last-child {
            text-align: right;
          }
        }
        i {
          font-style: normal;

          font-weight: 500;
          &.positive {
            color: #f04a5a;
          }
          &.negative {
            color: #f04a5a;
          }
        }
      }
      .kline-chart {
        width: 520px;
        height: 170px;
      }
    }
    .muti-kline-content {
      .tab-list {
        border-radius: 15px;
        background-color: #f3f7fc;
        width: 100%;
        display: flex;
        li {
          cursor: pointer;
          width: 33%;
          height: 30px;
          line-height: 30px;
          font-weight: 500;
          font-size: 14px;
          color: #354052;
          text-align: center;
          user-select: none;
          &.active {
            border-radius: 30px;
            background-image: linear-gradient(
              263deg,
              #c61b3d 108%,
              #c61b3d 41%
            );
            color: #fff;
          }
        }
      }
      .kline-chart {
        width: 520px;
        height: 150px;
      }
    }
    .common-dialog-footer {
      margin-top: 20px;
      .dialog-confirm-btn {
        width: 320px;
      }
      .dialog-status5-btn {
        cursor: auto;
        user-select: none;
        width: 320px;
        color: white;
        margin-left: 5px;
        border-radius: 3px;
        background-color: #c2c7d0;
        // background-image: linear-gradient(to right, #f78700 0%, #ffb152 100%);
        background-image: none;
      }
    }
  }
  .right-item {
    // height: 680px;
    position: relative;
    width: 420px;
    padding: 30px;
    background: #f3f7fc;
    border: solid 1px #e6e6e6;
    border-radius: 0 8px 8px 0;
    .icon-close {
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
    }
    .item-content {
      margin-bottom: 20px;
      .line-item-v2 {
        color: #9aa1b1;
        line-height: 1.57;
      }
    }
    .tip-box {
      margin: 0 auto;
      padding-top: 10px;
      position: relative;
      &:before {
        content: "";
        width: 200%;
        height: 200%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.5);
        box-sizing: border-box;
      }
      .line-item {
        display: flex;
        line-height: 24px;
        justify-content: space-between;
        color: #9aa1b1;
        font-size: 14px;
        .next {
          max-width: 278px;
          text-align: right;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // overflow: hidden;
        }
      }
    }

    .title {
      user-select: none;
      width: fit-content;
      padding: 0 12px;
      height: 26px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      color: #fff;
      justify-content: center;
      background-image: linear-gradient(262deg, #354052 105%, #404d66 -4%);
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .pie-component {
      // width: 690px;
      margin: 20px auto 0;
      border-radius: 12px;
      .sub-title {
        // width: 630px;
        margin: 15px 0 0;
        text-align: left;
        font-size: 14px;
        color: #9fa2aa;
        background: #f3f7fc;
        border-radius: 8px;
      }
      .chart-container {
        display: flex;
        .data-chart {
          width: 120px;
          height: 120px;
        }
        .legend {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 14px;
          .line-item {
            display: flex;
            align-items: center;
            margin-top: 10px;
            font-weight: 500;
            color: #9aa1b1;
            &.no {
              margin-top: 0;
            }
            i {
              display: block;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              margin-right: 18px;
              &.fir {
                background-color: #c61b3d;
              }
              &.sec {
                background-color: #3a7dff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
