var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-history-order"},[_c('div',{staticClass:"market-header"},[_c('ul',[_vm._l((_vm.tabList),function(item,index){return _c('li',{key:item.value,staticClass:"classify-area",class:{ active: _vm.currIndex == item.value },on:{"click":function($event){return _vm.onAreaChange(item)}}},[_vm._v(" "+_vm._s(_vm.translateTitle(item.label))+" "),(index == 0 && _vm.orderNum > 0)?_c('span',[_vm._v("("+_vm._s(_vm.orderNum)+")")]):_vm._e(),(_vm.currIndex == item.value)?_c('i',{staticClass:"item-bar"}):_vm._e()])}),_c('li',{staticClass:"hidden-btn"},[(_vm.currIndex == 2)?_c('el-checkbox',{model:{value:(_vm.hidenCancelChecked),callback:function ($$v) {_vm.hidenCancelChecked=$$v},expression:"hidenCancelChecked"}},[_vm._v(_vm._s(_vm.translateTitle("隐藏已撤销")))]):_vm._e(),_c('el-checkbox',{model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v(_vm._s(_vm.translateTitle("隐藏其他交易对")))])],1)],2)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.orderLoading),expression:"orderLoading"}],staticClass:"table-content",staticStyle:{"min-height":"290px"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[(_vm.currIndex == 1)?_c('el-table-column',{key:"1",attrs:{"prop":"create_time","align":"center","label":_vm.translateTitle('下单时间'),"min-width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.create_time)))])]}}],null,false,1445725579)}):_vm._e(),(_vm.currIndex == 2)?_c('el-table-column',{key:"10",attrs:{"prop":"create_time","align":"center","label":_vm.translateTitle('下单时间'),"min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.create_time)))])]}}],null,false,1445725579)}):_vm._e(),_c('el-table-column',{key:"2",attrs:{"align":"center","prop":"symbol_name","label":_vm.translateTitle('交易对'),"min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"common-operate",on:{"click":function($event){return _vm.onSymbolClick(row)}}},[_vm._v(_vm._s(row.symbol_name))])]}}])}),_c('el-table-column',{key:"3",attrs:{"align":"center","prop":"side","label":_vm.translateTitle('方向'),"min-width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:'side_' + row.side},[_vm._v(_vm._s(_vm.sideMap[row.side]))])]}}])}),(_vm.currIndex != 3)?_c('el-table-column',{key:"4",attrs:{"align":"right","prop":"price","label":_vm.translateTitle('委托价格'),"min-width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type == 1)?_c('div',[_c('span',{staticClass:"DINPro"},[_vm._v(_vm._s(row.price))])]):_c('div',[_c('span',[_vm._v(_vm._s(_vm.translateTitle("市价")))])])]}}],null,false,2272810852)}):_vm._e(),(_vm.currIndex != 1)?_c('el-table-column',{key:"5",attrs:{"align":"right","prop":"deal_avg_price","label":_vm.translateTitle('成交均价'),"min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status == 3)?_c('span',{staticClass:"DINPro"},[_vm._v("--")]):_c('span',{staticClass:"DINPro"},[_vm._v(_vm._s(row.deal_avg_price))])]}}],null,false,1376521505)}):_vm._e(),_c('el-table-column',{key:"6",attrs:{"align":"right","prop":"deal_num","label":_vm.translateTitle('委托数量'),"min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[(_vm.currIndex == 1)?_c('span',{staticClass:"DINPro"},[_vm._v(_vm._s(_vm.formatNumberRgx(row.num))+" "+_vm._s(row.coin_name))]):(_vm.currIndex == 2)?_c('span',{staticClass:"DINPro"},[_vm._v(_vm._s(_vm.formatNumberRgx(row.deal_num))+" "+_vm._s(row.coin_name)+" ")]):_vm._e()])]}}])}),_c('el-table-column',{key:"7",attrs:{"prop":"total","align":"right","min-width":"130","label":_vm.translateTitle('委托总额')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"DINPro"},[_vm._v(_vm._s(_vm.formatNumberRgx(row.total))+" "+_vm._s(row.coin_to_name))])]}}])}),(_vm.currIndex != 1)?_c('el-table-column',{key:"9",attrs:{"align":"right","prop":"fee","label":_vm.translateTitle('手续费'),"min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status == 3)?_c('span',{staticClass:"DINPro"},[_vm._v("--")]):_c('span',{staticClass:"DINPro"},[_vm._v(_vm._s(_vm.formatNumberRgx(row.fee))+" "),(row.side == 'buy')?_c('span',[_vm._v(" "+_vm._s(row.coin_name)+" ")]):_c('span',[_vm._v(_vm._s(row.coin_to_name))])])]}}],null,false,2077788332)}):_vm._e(),(_vm.currIndex != 1)?_c('el-table-column',{key:"8",attrs:{"align":"center","prop":"status","label":_vm.translateTitle('状态'),"min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{class:'status_' + row.status},[_vm._v(_vm._s(_vm.translateTitle(_vm.statusMap[row.status])))])]}}],null,false,2899510743)}):_vm._e(),(_vm.currIndex == 2)?_c('el-table-column',{key:"11",attrs:{"prop":"update_time","align":"center","label":_vm.translateTitle('完成时间'),"min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.update_time)))])]}}],null,false,1785958014)}):_vm._e(),(_vm.currIndex == 1)?_c('el-table-column',{key:"9",attrs:{"label":_vm.translateTitle('操作'),"align":"center","min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"cancel-btn",on:{"click":function($event){return _vm.onOpenConfirm(row.trust_id)}}},[_vm._v(_vm._s(_vm.translateTitle("撤销")))])]}}],null,false,328232878)}):_vm._e(),_c('no-data',{directives:[{name:"show",rawName:"v-show",value:(!_vm.orderLoading),expression:"!orderLoading"}],attrs:{"slot":"empty"},slot:"empty"})],1),(_vm.tableData.length > 0 && _vm.currIndex != 1)?_c('div',{staticClass:"common-table-pages"},[_c('p',[_vm._v(" "+_vm._s(_vm.translateTitle("共"))+" "),_c('span',[_vm._v(_vm._s(_vm.pageInfo.pageCount))]),_vm._v(" "+_vm._s(_vm.translateTitle("页"))+" ")]),_c('el-pagination',{staticClass:"mt20",attrs:{"background":"","layout":"prev, pager, next","total":_vm.pageInfo.pageTotal,"page-size":_vm.pageInfo.pageSize,"current-page":_vm.pageInfo.pageCurrent},on:{"update:currentPage":function($event){return _vm.$set(_vm.pageInfo, "pageCurrent", $event)},"update:current-page":function($event){return _vm.$set(_vm.pageInfo, "pageCurrent", $event)},"current-change":_vm.onCurrentPageChange}})],1):_vm._e()],1),_c('el-dialog',{attrs:{"title":_vm.translateTitle('操作提示'),"custom-class":"confirm-dialog","visible":_vm.dialogVisible,"lock-scroll":false,"width":"440px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(_vm.translateTitle("确认撤销此订单？")))]),_c('span',{staticClass:"common-dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('span',{staticClass:"dialog-cancel-btn",on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v(_vm._s(_vm.translateTitle("取消")))]),_c('span',{staticClass:"dialog-confirm-btn",on:{"click":_vm.onOK}},[_vm._v(_vm._s(_vm.translateTitle("确认")))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }