import api from "@/assets/js/api";

import {
  VUE_APP_UCENTER,
  VUE_APP_SOTC,
  VUE_APP_APP,
} from "@/assets/js/stationSetting";

const detailsUrl =
  "https://top6688.zendesk.com/api/v2/help_center/zh-cn/sections/360005486458/articles";

export function apiGetBannerList(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/App/Product/bannerList`,
    data,
  });
}
export function apiGetcustermerUrl(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/Publics/getcustermerUrl`,
    data,
  });
}

export function apiGetMainV2(data = {}) {
  return api({
    url: `${VUE_APP_SOTC}/SecretOtc/App/getMainV2`,
    data,
  });
}
