<template>
  <div class="markets-content-new scrollbar-default">
    <div class="markets-header-content">
      <div
        class="market-header-item"
        @click="
          onSymbolBlockClick(
            headerForm[22].from_symbol,
            headerForm[22].to_symbol
          )
        "
      >
        <div class="symbol-content">
          <span class="symbol">
            <img class="coin-url" :src="headerForm[22].coin_url" alt="" />
            <span class="coin-name">
              {{ headerForm[22].from_symbol }}/{{
                headerForm[22].to_symbol
              }}</span
            ></span
          >
          <span class="quote-change" :class="className">
            <span
              class="plus-icon"
              v-if="headerForm[22].price_change_percent > 0"
              >+</span
            >
            {{ headerForm[22].price_change_percent }}
            <span v-if="headerForm[22].price_change_percent != '--'">%</span>
          </span>
        </div>
        <div class="price-content">
          <span class="price DINPro" :class="className">
            {{ headerForm[22].last }}</span
          >
          <span class="fiat-price DINPro">
            ≈ {{ systemCurrencyMaps[systemCurrency] }}
            {{ headerForm[22].currency[systemCurrency] }}
          </span>
          <span class="fiat-price V2"
            >24H {{ translateTitle("成交量") }}
            <span class="DINPro" v-if="Number(headerForm[22].vol) != 0">{{
              formatNumberRgx(headerForm[22].vol)
            }}</span>
            <span v-else>--</span>
          </span>
        </div>
      </div>
      <div
        class="market-header-item"
        @click="
          onSymbolBlockClick(
            headerForm[23].from_symbol,
            headerForm[23].to_symbol
          )
        "
      >
        <div class="symbol-content">
          <span class="symbol">
            <img class="coin-url" :src="headerForm[23].coin_url" alt="" />
            <span class="coin-name"
              >{{ headerForm[23].from_symbol }}/{{
                headerForm[23].to_symbol
              }}</span
            ></span
          >
          <span class="quote-change" :class="classNameV2">
            <span
              class="plus-icon"
              v-if="headerForm[23].price_change_percent > 0"
              >+</span
            >
            {{ headerForm[23].price_change_percent }}
            <span v-if="headerForm[23].price_change_percent != '--'">%</span>
          </span>
        </div>
        <div class="price-content">
          <span class="price DINPro" :class="classNameV2">{{
            headerForm[23].last
          }}</span>
          <span class="fiat-price DINPro">
            ≈
            {{ systemCurrencyMaps[systemCurrency] }}
            {{ headerForm[23].currency[systemCurrency] }}
          </span>
          <span class="fiat-price V2"
            >24H {{ translateTitle("成交量") }}
            <span class="DINPro" v-if="Number(headerForm[23].vol) != 0">{{
              formatNumberRgx(headerForm[23].vol)
            }}</span>
            <span v-else>--</span>
          </span>
        </div>
      </div>
      <div
        class="market-header-item"
        @click="
          onSymbolBlockClick(
            headerForm[71].from_symbol,
            headerForm[71].to_symbol
          )
        "
      >
        <div class="symbol-content">
          <span class="symbol">
            <img class="coin-url" :src="headerForm[71].coin_url" alt="" />
            <span class="coin-name">
              {{ headerForm[71].from_symbol }}/{{
                headerForm[71].to_symbol
              }}</span
            ></span
          >
          <span class="quote-change" :class="classNameV3">
            <span
              class="plus-icon"
              v-if="headerForm[71].price_change_percent > 0"
              >+</span
            >
            {{ headerForm[71].price_change_percent }}
            <span v-if="headerForm[71].price_change_percent != '--'">%</span>
          </span>
        </div>
        <div class="price-content">
          <span class="price DINPro" :class="classNameV3">{{
            headerForm[71].last
          }}</span>
          <span class="fiat-price DINPro">
            ≈ {{ systemCurrencyMaps[systemCurrency] }}
            {{ headerForm[71].currency[systemCurrency] }}
          </span>
          <span class="fiat-price V2"
            >24H {{ translateTitle("成交量") }}
            <span class="DINPro" v-if="Number(headerForm[71].vol) != 0">{{
              formatNumberRgx(headerForm[71].vol)
            }}</span>
            <span v-else>--</span></span
          >
        </div>
      </div>
      <div
        class="market-header-item"
        @click="
          onSymbolBlockClick(
            headerForm[74].from_symbol,
            headerForm[74].to_symbol
          )
        "
      >
        <div class="symbol-content">
          <span class="symbol">
            <img class="coin-url" :src="headerForm[74].coin_url" alt="" />
            <span class="coin-name">
              {{ headerForm[74].from_symbol }}/{{
                headerForm[74].to_symbol
              }}</span
            ></span
          >
          <span class="quote-change" :class="classNameV4">
            <span
              class="plus-icon"
              v-if="headerForm[74].price_change_percent > 0"
              >+</span
            >
            {{ headerForm[74].price_change_percent }}
            <span v-if="headerForm[74].price_change_percent != '--'">%</span>
          </span>
        </div>
        <div class="price-content">
          <span>
            <span class="price DINPro" :class="classNameV4">{{
              headerForm[74].last
            }}</span>
            <span class="fiat-price DINPro">
              ≈ {{ systemCurrencyMaps[systemCurrency] }}
              {{ headerForm[74].currency[systemCurrency] }}
            </span>
          </span>

          <span class="fiat-price V2"
            >24H {{ translateTitle("成交量") }}
            <span class="DINPro" v-if="Number(headerForm[74].vol) != 0">{{
              formatNumberRgx(headerForm[74].vol)
            }}</span>
            <span v-else>--</span></span
          >
        </div>
      </div>
    </div>
    <div class="markets-content-detail">
      <div class="markets-detail">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="translateTitle('自选')" name="1"></el-tab-pane>
          <el-tab-pane
            :label="translateTitle('币币市场')"
            name="2"
          ></el-tab-pane>
          <!-- <el-tab-pane label="BTC" name="3"></el-tab-pane> -->
        </el-tabs>
        <div class="select-symbol">
          <!-- <el-input
            v-model="selectSymbol"
            maxlength="10"
            clearable
            @input="onFilterSymbol"
            placeholder="搜索"
          >
          </el-input> -->
          <el-select
            v-model.trim="selectSymbol"
            class="select-symbol-content"
            filterable
            @change="onSelect"
            @blur="onselectBlur"
            remote
            reserve-keyword
            :popper-append-to-body="false"
            :placeholder="translateTitle('搜索')"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in options"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
              <img
                :src="item.coin_url"
                alt=""
                style="width: 24px; height: 24px; vertical-align: middle"
              />
              <span style="margin-left: 10px">{{
                item.name.toUpperCase()
              }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="symbol-select" v-show="activeName == 2">
          <div
            class="symbol-option usdt"
            :class="activeSymbol == 1 ? 'active' : ''"
            @click="onSymbolChange(1)"
          >
            USDT
          </div>
          <div
            class="symbol-option btc"
            :class="activeSymbol == 1 ? '' : 'active'"
            @click="onSymbolChange(2)"
          >
            BTC
          </div>
        </div>
        <el-table
          v-loading="tableLoading"
          :data="tableDataShow"
          :expand-row-keys="expandList"
          @cell-click="onCellClick"
          row-key="symbol_id"
          style="width: 100%"
        >
          <el-table-column type="expand" width="1">
            <template slot-scope="{ row }">
              <div class="table-expand">
                <div class="expand-detail">
                  <p class="info-text" v-if="infoForm[row.symbol_id].info">
                    {{ infoForm[row.symbol_id].info }}
                    <!-- infoForm[row.symbol_id].info -->
                  </p>
                  <p v-else class="no-data">{{ translateTitle("暂无数据") }}</p>
                  <div class="expand-info">
                    <a
                      v-if="infoForm[row.symbol_id].office_site"
                      :href="infoForm[row.symbol_id].office_site"
                      target="_blank"
                      class="common-operate"
                      style="margin-right: 30px"
                    >
                      <i class="iconfont iconguanwang"></i>
                      {{ translateTitle("官网") }}
                    </a>
                    <a
                      v-if="infoForm[row.symbol_id].whitepager"
                      :href="infoForm[row.symbol_id].whitepager"
                      target="_blank"
                      class="common-operate"
                      style="margin-right: 30px"
                    >
                      <i class="iconfont iconbaipishu"></i>
                      {{ translateTitle("白皮书") }}
                    </a>
                    <a
                      v-if="infoForm[row.symbol_id].block_explorer"
                      :href="infoForm[row.symbol_id].block_explorer"
                      target="_blank"
                      class="common-operate"
                    >
                      <i class="iconfont iconchaxun"></i>
                      {{ translateTitle("区块查询") }}
                    </a>
                  </div>
                </div>
                <el-form label-position="top" class="common-expand-form">
                  <el-form-item :label="translateTitle('币种名称')">
                    <span>{{ infoForm[row.symbol_id].name }}</span>
                  </el-form-item>
                  <el-form-item :label="translateTitle('发行时间')">
                    <span>{{ infoForm[row.symbol_id].publish_time }}</span>
                  </el-form-item>
                  <el-form-item :label="translateTitle('发行总量')">
                    <span>{{ infoForm[row.symbol_id].total_supply }}</span>
                  </el-form-item>
                  <el-form-item :label="translateTitle('流通总量')">
                    <span>{{
                      infoForm[row.symbol_id].circulation_supply
                    }}</span>
                  </el-form-item>
                  <el-form-item :label="translateTitle('众筹价格')"
                    >--</el-form-item
                  >
                </el-form>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column width="60px" align="left">
            <template slot-scope="{ row }">
              <i
                class="el-icon-star-on active"
                @click="addOption(row, 2)"
                v-if="
                  selfOptionsList.indexOf(row.swap_symbol_id.toString()) > -1
                "
              ></i>
              <i class="el-icon-star-off" v-else @click="addOption(row, 1)"></i>
            </template>
          </el-table-column> -->
          <el-table-column
            label=""
            align="center"
            min-width="25"
            prop="from_symbol"
          >
            <template slot-scope="{ row }">
              <div style="text-align: left">
                <img
                  class="select-icon"
                  src="../../assets/img/exchange/active.png"
                  @click="addOption(row, 2)"
                  v-if="
                    selfOptionsList.length > 0 &&
                      selfOptionsList.indexOf(row.symbol_id.toString()) > -1
                  "
                  alt=""
                />
                <img
                  class="select-icon"
                  src="../../assets/img/exchange/noActive.png"
                  @click="addOption(row, 1)"
                  v-else
                  alt=""
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('交易对')"
            align="left"
            sortable
            min-width="155"
            prop="from_symbol"
          >
            <template slot-scope="{ row }">
              <div style="text-align: left">
                <!-- <img
                  class="select-icon"
                  src="../../assets/img/exchange/active.png"
                  @click="addOption(row, 2)"
                  v-if="selfOptionsList.indexOf(row.symbol_id.toString()) > -1"
                  alt=""
                />
                <img
                  class="select-icon"
                  src="../../assets/img/exchange/noActive.png"
                  @click="addOption(row, 1)"
                  v-else
                  alt=""
                /> -->
                <span
                  class="coin-content"
                  @click="onSymbolClick(row)"
                  style="cursor: pointer"
                >
                  <img class="coin-img" :src="row.coin_url" alt="" />
                  <span style="margin-left: 10px">{{ row.from_symbol }}</span>
                  <span class="dark-color"> / {{ row.to_symbol }}</span>
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            :label="translateTitle('最新价')"
            align="left"
            sortable
            prop="lastV2"
            min-width="150"
          >
            <template slot-scope="{ row }">
              <div @click="onSymbolClick(row)" class="cuper text-left">
                <span class="DINPro"> {{ row.last }} </span>
                <span class="dark-color DINPro"
                  >/
                  {{ systemCurrencyMaps[systemCurrency] }}
                  {{ row.currency[systemCurrency] }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('24H涨跌幅')"
            align="right"
            sortable
            prop="price_change_percentV2"
            class-name="no-padding-right"
            min-width="110"
          >
            <template slot-scope="{ row }">
              <span
                @click="onSymbolClick(row)"
                class="DINPro cuper"
                :class="row.price_change_percent > 0 ? 'green' : 'red'"
                ><span
                  class="green DINPro"
                  style="margin-right: -3px"
                  v-show="row.price_change_percent > 0"
                >
                  + </span
                >{{ row.price_change_percent }} %</span
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('24H最高')"
            align="right"
            sortable
            prop="highV2"
            min-width="140"
            class-name="no-padding-right"
          >
            <template slot-scope="{ row }">
              <span
                @click="onSymbolClick(row)"
                class="DINPro cuper dark-text"
                >{{ row.high }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('24H最低')"
            align="right"
            sortable
            prop="lowV2"
            min-width="140"
            class-name="no-padding-right"
          >
            <template slot-scope="{ row }">
              <span
                @click="onSymbolClick(row)"
                class="DINPro cuper dark-text"
                >{{ row.low }}</span
              >
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="24H成交量"
            align="center"
            sortable
            width="120"
          >
            <template slot-scope="{ row }">
              <p>{{ row.info.amt }}</p>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            label="24H成交额"
            align="center"
            sortable
            width="120"
          >
            <template slot-scope="{ row }">
              <p>{{ row.name }}</p>
            </template>
          </el-table-column> -->
          <el-table-column
            :label="translateTitle('操作')"
            align="right"
            min-width="125"
            class-name="caozuo"
          >
            <template slot-scope="{ row }">
              <div style="text-align: right">
                <div
                  class="pick-down"
                  @click="onDetail(row, 1)"
                  v-if="expandList.indexOf(row.symbol_id) == -1"
                >
                  <span class="common-operate V2">
                    {{ translateTitle("详情") }}
                  </span>
                  <i class="iconfont iconsize16-1" style="color: #7f8490"></i>
                </div>
                <div @click="onDetail(row, 2)" v-else class="pick-up">
                  <span class="common-operate V2">
                    {{ translateTitle("收起") }}
                  </span>
                  <!-- <i
                    class="iconfont iconxialashouqi"
                    style="color: #7f8490"
                  ></i> -->
                  <i
                    class="iconfont iconsize16-1 iconflip"
                    style="color: #7f8490"
                  ></i>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" width="15"> </el-table-column>
          <no-data
            slot="empty"
            v-show="!tableLoading && tableDataShow.length == 0"
          ></no-data>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import Socket from "@/assets/js/socket.js";
import NoData from "@/components/common/NoData";
import {
  apiGetCoinInfo,
  apiAddOption,
  apiGetOptionList
} from "@/model/markets/markets";
import { apiGetStaticTableInfo } from "@/model/settings";
import { mapGetters } from "vuex";
import { apiWsUrl } from "@/model/exchange/index";
import * as Account from "@/model/user/account";
import { apiGetSpotList } from "@/model/exchange/market.js";
import { systemCurrencyMaps } from "@/assets/js/keymap.js";
import commonMixin from "@/components/common/commonMixin.vue";
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  components: { NoData },
  data() {
    return {
      systemCurrencyMaps,
      voucherInterval: null,
      activeName: "2",
      activeSymbol: "1",
      selectSymbol: "",
      infoForm: {},
      loading: false,
      tableLoading: true,
      formLoadng: true,
      tableDataComputed: [],
      collectSymbol: [22],
      tableData: [],
      options: [],
      symbolList: [],
      expandList: [],
      coinInfoList: {},
      coinInfoListThirTeen: {},
      exchangeCoinMap: {},
      wsUrl: "",
      coinInfo: "",
      headerForm: {
        22: {
          coin_url:
            "https://imgs-dev.aevwjlae.com/admin/20201113/03397235421.png",
          symbol_id: 22,
          from_symbol: "BTC",
          to_symbol: "USDT",
          last: "--",
          ask: "",
          bid: "",
          vol: "0",
          price_change_percent: "--",
          currency: {
            ued: "--",
            cny: "--",
            cnye: "--",
            hkd: "--",
            jpy: "--",
            krw: "--",
            usd: "--"
          }
        },
        23: {
          coin_url:
            "https://imgs-dev.aevwjlae.com/admin/20201113/03397388660.png",
          symbol_id: 23,
          from_symbol: "ETH",
          to_symbol: "USDT",
          ask: "--",
          bid: "--",
          vol: "0",
          price_change_percent: "--",
          last: "--",
          currency: {
            ued: "--",
            cny: "--",
            cnye: "--",
            hkd: "--",
            jpy: "--",
            krw: "--",
            usd: "--"
          }
        },
        71: {
          coin_url:
            "https://imgs-dev.aevwjlae.com/admin/20210211/92752491010.png",
          symbol_id: 71,
          from_symbol: "LINK",
          to_symbol: "USDT",
          ask: "--",
          bid: "--",
          vol: "0",
          last: "--",
          price_change_percent: "--",
          currency: {
            ued: "--",
            cny: "--",
            cnye: "--",
            hkd: "--",
            jpy: "--",
            krw: "--",
            usd: "--"
          }
        },
        74: {
          coin_url:
            "https://imgs-dev.aevwjlae.com/admin/20210401/83568193825.png",
          symbol_id: 74,
          from_symbol: "DOGE",
          to_symbol: "USDT",
          ask: "--",
          vol: "0",
          bid: "--",
          last: "--",
          price_change_percent: "--",
          currency: {
            ued: "--",
            cny: "--",
            cnye: "--",
            hkd: "--",
            jpy: "--",
            krw: "--",
            usd: "--"
          }
        }
      },
      ws: null,
      selfOptionsList: []
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language"
    }),
    tableDataShow() {
      let tempArr = [];
      if (this.activeName == "1") {
        tempArr = this.tableData.filter((entry) => {
          if (this.selfOptionsList.indexOf(entry.symbol_id.toString()) != -1) {
            return entry;
          }
        });
      } else tempArr = this.tableDataComputed;
      return tempArr;
    },
    className() {
      let className = "";
      if (!this.headerForm[22].price_change_percent) {
        className = "";
      } else if (this.headerForm[22].price_change_percent > 0) {
        className = "green";
      } else if (this.headerForm[22].price_change_percent < 0) {
        className = "red";
      }
      return className;
    },
    classNameV2() {
      let className = "";
      if (!this.headerForm[23].price_change_percent) {
        className = "";
      } else if (this.headerForm[23].price_change_percent > 0) {
        className = "green";
      } else if (this.headerForm[23].price_change_percent < 0) {
        className = "red";
      }
      return className;
    },
    classNameV3() {
      let className = "";
      if (!this.headerForm[71].price_change_percent) {
        className = "";
      } else if (this.headerForm[71].price_change_percent > 0) {
        className = "green";
      } else if (this.headerForm[71].price_change_percent < 0) {
        className = "red";
      }
      return className;
    },
    classNameV4() {
      let className = "";
      if (!this.headerForm[74].price_change_percent) {
        className = "";
      } else if (this.headerForm[74].price_change_percent > 0) {
        className = "green";
      } else if (this.headerForm[74].price_change_percent < 0) {
        className = "red";
      }
      return className;
    }
  },
  async created() {
    this.getOptionList();
    await this.getStaticTableInfo(1);
    await this.getStaticTableInfoV2(13);
    await this.getExchangeCoinMap();
    await this.initURL();
    this.initWS();
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  methods: {
    routeSplicing,
    // 千分符
    formatNumberRgx(num) {
      if (!num) {
        return;
      }
      var parts = num.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    async getExchangeCoinMap() {
      const { status, data } = await apiGetStaticTableInfo(55);
      if (status == 200) {
        let tempObj = {};
        for (const key in data) {
          if (data[key].symbol_type == 1 && data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        this.exchangeCoinMap = JSON.parse(JSON.stringify(tempObj));
        for (const key in this.exchangeCoinMap) {
          for (const index in this.coinInfoListThirTeen) {
            if (
              this.exchangeCoinMap[key].coin_from ==
              this.coinInfoListThirTeen[index].item_id
            ) {
              this.exchangeCoinMap[
                key
              ].from_item_name = this.coinInfoListThirTeen[index].item_name;
              this.exchangeCoinMap[
                key
              ].from_coin_id = this.coinInfoListThirTeen[index].item_tag;
            }
            if (
              this.exchangeCoinMap[key].coin_to ==
              this.coinInfoListThirTeen[index].item_id
            ) {
              this.exchangeCoinMap[
                key
              ].to_item_name = this.coinInfoListThirTeen[index].item_name;
              this.exchangeCoinMap[key].to_coin_id = this.coinInfoListThirTeen[
                index
              ].item_tag;
            }
          }
        }
        for (const key in this.exchangeCoinMap) {
          for (const index in this.coinInfoList) {
            if (
              this.exchangeCoinMap[key].from_coin_id ==
              this.coinInfoList[index].coin_id
            ) {
              this.exchangeCoinMap[key].coin_url = this.coinInfoList[
                index
              ].coin_url;
            }
          }
        }
      }
    },
    initWS() {
      this.tableLoading = true;
      let wsUrl = this.wsUrl;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "market",
            app_id: VUE_APP_ID
          }
        },
        callback: this.onResponse
      };
      window.USDT_WS = this.ws = new Socket(option).getInstance();
    },
    getSubData() {
      var json = {
        data: {
          type: "market",
          app_id: VUE_APP_ID
        },
        action: "Topic.sub"
      };
      this.ws.sub(json);
    },
    handleResPonse(data) {
      if (this.time == 1) {
        this.onResponse(data);
        this.time = 2;
      } else {
        setTimeout(() => {
          this.onResponse(data);
        }, 4000);
      }
    },

    async initURL() {
      let params = {
        __version: "3.4.3"
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws_url;
      }
    },
    onResponse(data) {
      if (data.action == "Pushdata.market") {
        this.tableData = data.data;
        if (this.tableData == null || this.tableData.length == 0) {
          return;
        }
        this.tableData.forEach((ele) => {
          ele.currency.usdV2 = Number(ele.currency.usd);
          ele.lastV2 = Number(ele.last);
          ele.price_change_percentV2 = Number(ele.price_change_percent);
          ele.price_change_percentV2 = Number(ele.price_change_percent);
          ele.highV2 = Number(ele.high);
          ele.lowV2 = Number(ele.low);
          for (const key in this.exchangeCoinMap) {
            if (key == ele.symbol_id) {
              ele["from_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[0]
                .toUpperCase();
              ele["to_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[1]
                .toUpperCase();
              ele["info"] = this.exchangeCoinMap[key];
            }
          }

          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (
                this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
              ) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
          // console.log(this.tableData);
          if (
            ele.symbol_id == 22 ||
            ele.symbol_id == 23 ||
            ele.symbol_id == 71 ||
            ele.symbol_id == 74
          ) {
            this.headerForm[ele.symbol_id] = ele;
            for (const key in this.headerForm) {
              this.headerForm[key].vol = Number(
                this.headerForm[key].vol
              ).toFixed(2);
            }
          }
        });

        this.tableDataComputed = this.tableData.filter((ele) => {
          if (this.activeSymbol == 1) {
            return ele.from_symbol != undefined && ele.info.coin_to == "10007";
          } else if (this.activeSymbol == 2) {
            return ele.from_symbol != undefined && ele.info.coin_to == "10005";
          }
        });
        this.tableLoading = false;
      }
      // console.log(this.tableData);
    },
    onSymbolBlockClick(from_symbol, to_symbol) {
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${from_symbol.toLowerCase()}_${to_symbol.toLowerCase()}`
      );
      window.open(tempUrl);
    },
    onCellClick(row, column, cell, event) {
      if (column.label == "" || column.label == this.translateTitle("操作")) {
        return;
      }
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      );
      window.open(tempUrl);
    },
    onSymbolClick(row) {
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      );
      // window.open(tempUrl);
    },
    onSymbolChange(num) {
      this.activeSymbol = num;
      this.tableDataComputed = this.tableData.filter((ele) => {
        if (this.activeSymbol == 1) {
          return ele.info.coin_to == "10007";
        } else if (this.activeSymbol == 2) {
          return ele.info.coin_to == "10005";
        }
      });
    },
    onDetail(row, type) {
      if (type == 1) {
        this.expandList = [];
        this.expandList.push(row.symbol_id);
        this.getCoinInfo(row);
      } else {
        this.expandList = [];
      }
    },
    onFilterSymbol(val) {
      this.selectSymbol = val.replace(/[^a-zA-Z\d/]/g, "");
    },

    getCoinInfo(row) {
      this.formLoadng = true;
      let params = {
        swap_symbol_id: row.symbol_id
      };
      apiGetCoinInfo(params).then((res) => {
        if (res.status == 200) {
          this.infoForm[row.symbol_id] = res.data;
          this.coinInfo = this.infoForm[row.symbol_id].info;
        }
        this.formLoadng = false;
      });
    },
    addOption(row, type) {
      if (this.userInfo) {
        let params = {
          symbol_id: row.symbol_id,
          option: type,
          swap_type: 2
        };
        apiAddOption(params).then((res) => {
          if (res.status == 200) {
            this.getOptionList();
            this.$message({
              message:
                params.option == 1
                  ? this.translateTitle("添加自选成功")
                  : this.translateTitle("删除自选成功"),
              type: params.option == 1 ? "success" : "success"
            });
          }
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: { redirect: this.$route.fullPath }
        });
      }
    },
    handleClick(tab, event) {
      if (tab.name == 1 && !this.userInfo) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: { redirect: this.$route.fullPath }
        });
      }
    },

    async getOptionList() {
      let { userInfo, accountInfo } = await Account.getUserInfo();
      if (!userInfo) {
        return;
      }
      apiGetOptionList().then((res) => {
        if (res.status == 200 && JSON.stringify(res.data) != `[]`) {
          if (res.data.digital) {
            this.selfOptionsList = res.data.digital;
          }
        }
      });
    },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.coinInfoList = JSON.parse(JSON.stringify(data));
      }
    },
    async getStaticTableInfoV2(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.coinInfoListThirTeen = JSON.parse(JSON.stringify(data));
      }
    },
    // 做交易对搜索
    // async getTradePairList() {
    //   const { status, data } = await apiGetSpotList();
    //   if (status == 200) {
    //     let tempArr = data;
    //     tempArr.forEach((ele) => {
    //       for (const key in this.coinInfoList) {
    //         if (this.coinInfoList[key].name == ele.from_symbol.toLowerCase()) {
    //           ele.coin_url = this.coinInfoList[key].coin_url;
    //         }
    //       }
    //     });
    //     this.symbolList = tempArr;
    //   }
    // },
    remoteMethod(query) {
      query = query.trim();
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          let tempArr = [];
          for (const key in this.exchangeCoinMap) {
            tempArr.push(this.exchangeCoinMap[key]);
          }
          this.options = tempArr.filter((item) => {
            if (item.from_item_name) {
              return (
                item.from_item_name.toLowerCase().indexOf(query.toLowerCase()) >
                  -1 ||
                item.to_item_name.toLowerCase().indexOf(query.toLowerCase()) >
                  -1 ||
                item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                (
                  item.from_item_name.toLowerCase() +
                  item.to_item_name.toLowerCase()
                ).indexOf(query.toLowerCase()) > -1
              );
            }
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    onselectBlur() {
      setTimeout(() => {
        this.options = [];
        this.selectSymbol = "";
      }, 1000);
    },
    onSelect(value) {
      let tempValue = "";
      if (value) {
        tempValue = value.replace("/", "_").toLowerCase();
        let tempUrl = this.routeSplicing(
          this.language,
          `exchange/${tempValue}`
        );
        window.open(tempUrl);
      }
      this.selectSymbol = "";
    }
  }
};
</script>
<style lang="scss">
.markets-content-new {
  padding: 50px 0 0px;
  min-height: 1000px;
  /* background: url("../../assets/svg/banner.png") 0 0 repeat; */
  background-size: 100% 240px;
  background-color: #f1f2f5;
  color: #fff;
  text-align: center;
  user-select: none;
  .markets-content-detail {
    background: #f1f2f5;
    overflow: hidden;
    // min-height: 834px;
    .markets-detail {
      position: relative;
      width: 1200px;
      min-height: 966px;
      padding: 10px 30px 33px;
      border-radius: 6px;
      margin: 20px auto 30px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
      background-color: #ffffff;
      .el-tabs__active-bar {
        background-color: #c61b3d;
        height: 3px;
      }
      .symbol-select {
        display: flex;
        .symbol-option {
          width: 110px;
          height: 30px;
          line-height: 20px;
          font-size: 14px;
          margin: 5px 0 13px;
          padding: 5px 38px 5px 34px;
          color: #7f8490;
          cursor: pointer;
          display: inline-block;
          border: solid 1px #e1e2e6;
          &.usdt {
            border-radius: 100px 0 0 100px;
          }
          &.btc {
            border-radius: 0 100px 100px 0;
          }
          &.active {
            color: #fff;
            border: solid 1px #c61b3d;
            background-color: #c61b3d;
          }
        }
      }
      .el-table {
        min-height: 450px;

        .ascending {
          .sort-caret.ascending {
            border-bottom-color: #354052;
          }
        }
        .el-table__header {
          .no-padding-right {
            .cell {
              padding-right: 0;
            }
          }
        }
        .el-table__empty-block {
          min-height: 558px;
        }
        .el-table__header {
          thead {
            tr th {
              padding: 4px 0;
              background: #f5f5f7;
            }
            // .el-table__expand-column{
            //   display: none;
            // }
          }
        }

        tbody {
          // .el-table__expand-column{
          //   display: none;
          // }
          .caozuo {
            .cell {
              padding-right: 0;
            }
          }
          .cell {
            cursor: pointer;
            .common-operate {
              &.V2 {
                font-weight: normal;
              }
            }
            .select-icon {
              width: 16px;
              height: 16px;
              cursor: pointer;
              vertical-align: middle;
              margin-top: -2px;
              margin-right: 10px;
            }
            .common-operate {
              font-size: 14px;
              color: #3a7dff;
              font-weight: 500;
            }
            .iconfont {
              font-size: 13px;
              opacity: 0.6;
              color: #9aa1b1 !important;
              display: inline-block;
              width: 13px;
              &.iconxialashouqi {
                margin-right: 4px;
              }
            }
            .iconflip {
              transform: rotate(180deg);
            }
            .cuper {
              cursor: pointer;
            }
            .dark-text {
              color: #354052;
            }
            .coin-content {
              display: inline-block;
              width: 85%;
              .coin-img {
                width: 30px;
                height: 30px;
                vertical-align: middle;
              }
            }
            .red {
              color: #c61b3d;
            }
            .green {
              color: #03ad79;
            }
            .active {
              color: #fa4d56;
              font-size: 18px;
              margin-top: 4px;
              margin-left: -3px;
            }
            .el-icon-star-on,
            .el-icon-star-off {
              cursor: pointer;
              // margin-right: 10px;
              width: 15%;
            }
            p,
            span,
            code {
              color: #1f2533;
              font-weight: 500;
            }
            .el-table__expand-icon {
              display: none;
            }
            .dark-color {
              color: #9aa1b1;
              font-size: 12px;
              font-weight: normal;
            }
          }
        }

        .table-expand {
          min-height: 100%;
          // padding: 10px;
          display: flex;
          cursor: auto;
          .expand-detail {
            flex: 1;
            display: inline-block;
            margin-right: 32px;
            position: relative;
            .no-data {
              // height: 100%;
              // margin: 0 auto;
              // text-align: center;
              // line-height: 1;
              // margin-top: 100px;
            }
            .info-text {
              max-height: 180px;
              overflow: hidden;
              font-size: 12px;
              line-height: 1.83;
              overflow-x: hidden;
              overflow-y: scroll;
              color: #354052;
            }
            .info-text::-webkit-scrollbar {
              display: none;
            }
            .expand-info {
              position: absolute;
              bottom: -40px;
              left: 0;
              .iconfont {
                font-size: 13px;
                margin: 3px;
              }
            }
          }
          .el-form {
            display: inline-block;
            width: 300px;
            .el-form-item {
              margin-bottom: 0px;
              padding: 0 20px;
              .el-form-item__label {
                padding: 0;
              }
              &:nth-child(2n + 1) {
                background-color: #f5f5f7;
              }
            }
            .el-form-item__content {
              display: inline-block;
              float: right;
            }
          }
        }
      }
      .el-tabs__item {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .select-symbol {
        width: 200px;
        height: 30px;
        position: absolute;
        top: 15px;
        right: 30px;
        .el-input__inner {
          background: url("../../assets/img/select-icon.png") no-repeat; //引入icon
          background-size: 14px 14px; //这个是图片的大小，在这里不能直接设置width  height,设置宽高其实是select的宽高，图片可能会失真只设置宽度  高度auto也行
          background-position: 9px 8px; //在input中定位置  这两个参数是x  y坐标
          padding: 0 0 0 26px; //需要设置padding 把placeholder向右移
          box-sizing: border-box;
          font-size: 14px;
        }

        .el-input {
          .el-input__inner {
            width: 200px;
            height: 30px;
            padding: 5px 10px 5px 30px;
            border-radius: 16px;
            background-color: #f1f2f5;
          }
          .el-input__icon {
            line-height: 32px;
          }
        }
        .el-select-dropdown__list {
          padding-bottom: 14px;
        }
        .el-select-dropdown__item {
          text-align: left;
        }
      }
    }
  }
  .markets-header-content {
    width: 1200px;
    min-height: 131px;
    margin: 0 auto;
    display: flex;
    padding-bottom: 25px;

    .market-header-item {
      width: 290px;
      height: 106px;
      margin: 0 13px 0 0;
      padding: 12px 12px 10px 16px;
      border-radius: 3px;
      background-color: #000000;
      border: solid 1px #000000;
      cursor: pointer;
      &:hover {
        border: solid 1px #3a7dff;
      }
      &:last-child {
        margin-right: 0;
      }
      .symbol-content {
        height: 26px;
        cursor: pointer;
        .symbol {
          float: left;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          .coin-url {
            width: 24px;
            height: 24px;
            vertical-align: middle;
          }
          .coin-name {
            vertical-align: middle;
            margin-left: 5px;
          }
        }
        .quote-change {
          height: 24px;
          // line-height: 13px;
          display: inline-block;
          float: right;
          font-size: 12px;
          background: rgba(127, 132, 144, 0.3);
          color: #fff;
          padding: 3px 9px;
          border-radius: 3px;
          min-width: 60px;
          &.green {
            background: rgba(3, 173, 121, 0.3);
            color: #03ad79;
            padding: 3px 9px;
          }
          &.red {
            background: rgba(198, 27, 61, 0.3);
            color: #c61b3d;
            padding: 3px 9px;
          }
          .plus-icon {
            margin-right: -2px;
          }
        }
      }
      .price-content {
        padding-top: 13px;
        text-align: left;
        .price {
          color: #bec1c8;
          &.green {
            color: #03ad79;
            font-size: 16px;
          }
          &.red {
            color: #c61b3d;
            font-size: 16px;
          }
        }
        .fiat-price {
          color: #bec1c8;
          font-size: 12px;
          &.V2 {
            display: block;
          }
        }
      }
    }
  }
  .el-table__expanded-cell {
    margin: 58px 0.5px 0 0;
    padding: 17px 30px 33px 29.5px;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
    // box-sizing: border-box;
    background-color: #ffffff;
    .el-form {
      .el-form-item {
        .el-form-item__label {
          font-size: 12px;
        }
        .el-form-item__content {
          font-size: 12px;
        }
      }
    }
    .expand-detail {
      width: 740px;
      color: #354052;
      height: 166px;
      font-size: 14px;
      text-align: left;
      .common-operate {
        font-size: 12px;
      }
    }
  }
  .pick-down {
    .common-operate {
      width: 28px !important;
    }
  }
}
</style>
