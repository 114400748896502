<template>
  <div class="finances-managerment scrollbar-default">
    <div class="header">
      <div class="header-content">
        <p class="first-item">{{ translateTitle("金融理财") }}</p>
        <p class="second-item">
          {{ translateTitle("期限灵活、收益稳健、高收益、无风险") }}
        </p>
      </div>
    </div>
    <div class="list-content">
      <div class="type-list">
        <div class="list-type">
          <div
            v-for="(item, index) in listType"
            :key="index"
            class="tab"
            :class="{ active: item.val === active }"
            @click="onTypeChange(item.val)"
          >
            <span>{{ translateTitle(item.text) }} </span>
            <i class="type-indicator"></i>
          </div>
        </div>
        <span class="my-holder" @click="goToMyHolder"
          >{{ translateTitle("我的持有") }}
          <span><i class="icongengduo iconfont"></i></span
        ></span>
      </div>
    </div>
    <div class="finances-content" v-loading="pageLoading">
      <div>
        <div
          class="finances-item"
          v-for="item in dataList"
          :class="'status' + '_' + item.status"
          :key="item.product_id"
        >
          <p>
            <img v-if="item.product_type == 4" :src="item.to_item_url" alt="" />
            <img v-else :src="item.item_url" alt="" />
            <span class="title">{{ item.product_name }}</span>
          </p>
          <div
            class="type-content"
            v-if="
              (item.status == 1 || item.status == 2) &&
                item.start_time != 0 &&
                item.remain_time > 0
            "
          >
            <span class="type-1" v-if="item.status == 1">
              {{ translateTitle("募集预热中") }}
            </span>
            <div class="type-progress" v-else>
              <span class="quant-tag" style="margin-left:7px">
                <span
                  class="type-7"
                  v-if="
                    item.quant_tag != 0 && item.status > 3 && item.status != 5
                  "
                >
                  {{ translateTitle(quantTagMaps.label[item.quant_tag]) }}
                </span>
                <span
                  :class="'quant-type' + '-' + item.quant_tag"
                  v-else-if="item.quant_tag != 0"
                >
                  {{ translateTitle(quantTagMaps.label[item.quant_tag]) }}
                </span>
              </span>
              <!-- v-if="remain_time" -->
              <Countdown
                :key="item.product_id"
                :time="item.remain_time / 1000"
                format="hh:mm:ss"
                @on-end="onCountDownEnd()"
              >
                <template slot-scope="{ time }" class="DINPro">
                  <span class="DINPro">{{ time }}</span>
                  {{ translateTitle("后开幕") }}
                </template>
              </Countdown>
            </div>
          </div>
          <div
            class="type-content"
            v-else-if="
              (item.status == 1 || item.status == 2 || item.status == 3) &&
                item.remain_end_time
            "
          >
            <div class="type-progress V2">
              <span class="quant-tag" style="margin-left:7px">
                <span
                  class="type-7"
                  v-if="
                    item.quant_tag != 0 && item.status > 3 && item.status != 5
                  "
                >
                  {{ translateTitle(quantTagMaps.label[item.quant_tag]) }}
                </span>
                <span
                  :class="'quant-type' + '-' + item.quant_tag"
                  v-else-if="item.quant_tag != 0"
                >
                  {{ translateTitle(quantTagMaps.label[item.quant_tag]) }}
                </span>
              </span>
              <Countdown
                class="progress-count"
                v-if="item.remain_end_time"
                :key="item.product_id"
                :time="item.remain_end_time / 1000"
                format="hh:mm:ss"
                @on-end="onCountDownEnd()"
              >
                <template slot-scope="{ time }" class="DINPro">
                  <span class="DINPro">{{ time }}</span>
                  {{ translateTitle("后募集结束") }}
                </template>
              </Countdown>
            </div>
          </div>
          <p class="type-content" v-else>
            <span
              class="type-7"
              v-if="item.quant_tag != 0 && item.status > 3 && item.status != 5"
            >
              {{ translateTitle(quantTagMaps.label[item.quant_tag]) }}
            </span>
            <span
              :class="'quant-type' + '-' + item.quant_tag"
              v-else-if="item.quant_tag != 0"
            >
              {{ translateTitle(quantTagMaps.label[item.quant_tag]) }}
            </span>
            <span
              class="type-3"
              v-if="
                item.union_info.length > 0 ||
                  (item.period == 2 &&
                    (item.status == 1 || item.status == 2 || item.status == 3))
              "
            >
              {{ translateTitle("随时可存") }}
            </span>
            <span
              class="type-red common-btn"
              v-if="
                item.product_type != 2 && item.period != 2 && item.status == 3
              "
            >
              {{ translateTitle("募集中") }}
            </span>
            <span
              :class="'type-' + `${item.status}`"
              v-if="item.status > 3 && item.product_type == 4"
            >
              {{ translateTitle(financialStatusMapsTwo.label[item.status]) }}
            </span>
            <span
              :class="'type-' + `${item.status}`"
              v-if="item.status > 3 && item.product_type != 4"
            >
              {{ translateTitle(financialStatusMaps.label[item.status]) }}
            </span>
          </p>

          <p class="center-content" :class="item.product_type == 3 ? 'V2' : ''">
            <span v-if="item.product_type == 4">
              <span class="number-item">
                {{ item.to_item_id_price }}
                <span
                  style="font-size:14px;display: inline-block;margin-left:-10px"
                  >{{ item.item_name }}</span
                >
              </span>

              <span class="text-item" style="height:20px">
                {{ translateTitle("价格") }}
              </span>
            </span>
            <span v-else>
              <span class="number-item" v-if="item.union_info.length > 0">
                {{ annualRateComputed(item.active_annual_rate) }}%
              </span>
              <span class="number-item" v-else>
                {{ annualRateComputed(item.annual_rate) }}%
              </span>
              <span
                class="text-item"
                v-if="
                  ((item.product_type == 2 || item.product_type == 3) &&
                    item.rate_type == 1) ||
                    item.product_type == 1
                "
              >
                {{ translateTitle("固定年化") }}
              </span>
              <span v-else class="text-item">
                {{ translateTitle("历史最高年化") }}
              </span>
            </span>
            <span
              class="lock-period-content"
              v-if="
                (item.product_type == 1 || item.product_type == 3) &&
                  item.union_info.length > 0
              "
            >
              <span
                v-for="prod in item.union_info"
                :key="prod.product_id"
                class="lock-period"
                @click="onLockPeriodChange(prod, item)"
                :class="
                  item.active_lock_period_id == prod.product_id ? 'active' : ''
                "
                >{{ prod.lock_period }}{{ translateTitle("天") }}</span
              >
            </span>
            <span
              class="text-item V2"
              v-else-if="
                item.product_type != 2 &&
                  item.product_type != 4 &&
                  (item.remain_time > 0 || item.status == 1) &&
                  item.amount > 0
              "
            >
              {{ translateTitle("总额") }}
              {{ Number(item.remaining) }} {{ item.item_name }}</span
            >

            <span class="text-item V2" v-else-if="item.product_type == 2">
              <!-- {{ Number(item.min_amount) }} {{ item.item_name }}
              {{ translateTitle("起存") }} -->
            </span>
            <span
              class="text-item V2"
              v-else-if="item.product_type != 2 && item.amount > 0"
            >
              <span
                v-if="item.remaining == item.amount"
                style="display:inline-block"
              >
                {{ translateTitle("总额") }}
                {{ Number(item.remaining) }} {{ item.item_name }}
              </span>
              <span v-else style="display:inline-block">
                {{ translateTitle("剩余") }}
                {{ Number(item.remaining) }}
                <span
                  v-if="item.product_type == 4"
                  style="display:inline-block"
                >
                  {{ item.to_item_name }}
                </span>
                <span v-else style="display:inline-block">
                  {{ item.item_name }}
                </span>
              </span>
              <span
                style="display:inline-block;margin-left:4px"
                v-if="item.remaining == item.amount"
                >(100%)</span
              >
              <span style="display:inline-block;margin-left:4px" v-else>
                ({{ remainingPercent(item.remaining, item.amount) }}%)
                <!-- ({{
                  (()
                    .toString()
                    .substring(
                      0,
                      ((item.remaining * 100) / item.amount)
                        .toString()
                        .indexOf(".") +
                        2 +
                        1
                    )
                }}) -->
              </span>
            </span>
            <span class="text-item V2" v-else-if="item.amount == 0">
              {{ translateTitle("不限额") }}
            </span>
            <span class="text-item V2" v-else></span>

            <span
              class="date-item"
              v-if="item.product_type != 2 && item.remain_end_earning_day"
            >
              {{ translateTitle("距离到期") }}
              {{ item.remain_end_earning_day }}{{ translateTitle("天") }}</span
            >
            <span class="date-item" v-else-if="item.union_info.length == 0">
              {{ Number(item.min_amount) }} {{ item.item_name }}
              {{ translateTitle("起存") }}
            </span>
            <span v-else style="height:37px"></span>
            <span
              class="common-btn disabled-btn"
              v-if="item.status > 3 && item.status < 5"
              @click="goToBuy(item)"
              >{{ translateTitle("募集结束") }}</span
            >
            <span
              class="common-btn disabled-btn"
              v-else-if="item.status == 5 && item.product_type == 4"
              @click="goToBuy(item)"
              >{{ translateTitle("交付中") }}</span
            >
            <span
              class="common-btn disabled-btn"
              v-else-if="item.status == 5 && item.product_type != 4"
              @click="goToBuy(item)"
              >{{ translateTitle("计息中") }}</span
            >
            <span
              class="common-btn disabled-btn"
              v-else-if="item.status == 6"
              @click="goToBuy(item)"
              >{{ translateTitle("本息发放中") }}</span
            >
            <span
              class="common-btn disabled-btn"
              v-else-if="item.status == 7 && item.product_type == 4"
              @click="goToBuy(item)"
              >{{ translateTitle("交付完成") }}</span
            >
            <span
              class="common-btn disabled-btn"
              v-else-if="item.status == 7 && item.product_type != 4"
              @click="goToBuy(item)"
              >{{ translateTitle("本息已到账") }}</span
            >
            <span
              class="common-btn disabled-btn"
              v-else-if="item.status == 8"
              @click="goToBuy(item)"
              >{{ translateTitle("募集失败") }}</span
            >

            <span class="common-btn btn" v-else @click="goToBuy(item)">
              {{ translateTitle("存入") }}
            </span>
          </p>
        </div>
      </div>
      <no-data
        slot="empty"
        v-if="dataList.length == 0 && pageLoading == false"
      />
    </div>
    <el-dialog
      width="1000"
      :custom-class="['finance-dialog', heightClass]"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="financialDialogVisible"
    >
      <financial-dialog
        v-if="financialDialogVisible"
        @onProductInfoChange="onProductInfoChange"
        @onDialogClose="onDialogClose"
        @onConfirm="onConfirm"
        :productId="productId"
      ></financial-dialog>
    </el-dialog>
    <el-dialog
      width="470px"
      :title="confirmDialogTitle"
      custom-class="confirm-dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="confirmDialogVisible"
    >
      <confirm-dialog-content
        v-if="confirmDialogVisible"
        :productId="productId"
        :productInfo="productInfo"
        @onCancel="onConfirmCancel"
        @onConfirm="onConfirmOK"
      >
      </confirm-dialog-content>
    </el-dialog>
    <el-dialog
      width="440px"
      :title="translateTitle('提示信息')"
      custom-class="success-dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="successDialogVisible"
    >
      <div class="dialog-content">
        <img src="../../assets/img/success.png" alt="" />
        <p>
          {{ translateTitle("存入成功") }}
        </p>
      </div>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="onSuccess">{{
          translateTitle("稍后查看")
        }}</span>
        <el-button class="dialog-confirm-btn" @click="goToUseCenter">{{
          translateTitle("查看持仓")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import { mapGetters } from "vuex";
import {
  apiGetProductListV2,
  apiGetProductInfo,
} from "@/model/finance/finance";
import { apiGetAssets } from "@/model/exchange/index";
import {
  quantTagMaps,
  financialStatusMaps,
  financialStatusMapsTwo,
} from "@/assets/js/keymap.js";
import NoData from "@/components/common/NoData";
import commonMixin from "@/components/common/commonMixin.vue";
import { timestampToDate } from "@/assets/js/formatDate";
import FinancialDialog from "./components/financial-management/financial-dialog";
import ConfirmDialogContent from "./components/financial-management/confirm-dialog.vue";
// import Countdown2 from "@choujiaojiao/vue2-countdown";
import Countdown from "@/components/common/countdown.vue";
import BigNumber from "bignumber.js";
// import Countdown from "vue2-countdown";
// import countdown from "@components/common/countdown.vue";
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import { floorNumber, ceilNumber } from "@/assets/js/digital";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  name: "finances-index",
  components: {
    NoData,
    // Countdown,
    // Countdown2,
    Countdown,
    FinancialDialog,
    ConfirmDialogContent,
  },
  data() {
    return {
      quantTagMaps,
      financialStatusMaps,
      financialStatusMapsTwo,
      buyNumber: "",
      voucherInterval: null,
      rightForm: {},
      dataList: [],
      productTypeList: [],
      confirmDialogTitle: this.translateTitle(`量化理财`),
      listType: [
        { text: "全部", val: 0 },
        // { text: "量化理财", val: 3 },
        // { text: "活期", val: 2 },
        // { text: "定期", val: 1 },
        // { text: "汇信池", val: 4 },
      ],
      active: 0,
      rate1: 1,
      rate2: 1,
      currTab: 1,
      pageLoading: true,
      financialDialogVisible: false,
      confirmDialogVisible: false,
      successDialogVisible: false,
      productId: "",
      apiAssetsInfo: "",
      productInfo: {
        product_id: 33,
        product_name: "--",
        item_id: 10007,
        channel_id: 101,
        item_type: 1,
        to_item_id: 10069,
        to_item_id_price: "--",
        product_type: 4,
        rate_type: 1,
        quant_tag: 0,
        annual_rate: "--",
        min_fluctuation_annual_rate: "--",
        max_fluctuation_annual_rate: "--",
        day_rate: "--",
        current_rate: "--",
        period: 1,
        start_time: 0,
        stop_time: 0,
        lock_period: "--",
        amount: 0,
        lowest_amount: 0,
        total_amount: "--",
        min_amount: "--",
        max_amount: "--",
        per_min_num: 1,
        per_max_num: 200,
        number_precision: 2,
        price_precision: 2,
        amount_precision: 2,
        trader_profit: 0,
        status: 7,
        union_product_id: 0,
        union_order_by: 1,
        is_union_master: 0,
        public_raise_amount: "--",
        ico_buy_condition: 1,
        ico_user_register_time: 0,
        is_hide: 0,
        is_show_list: 1,
        is_recommand: 1,
        is_delete: 0,
        access_type: 1,
        order_by: 1,
        i18n_lang: "static_finances_product_product_name_330",
        create_time: 1620324487,
        update_time: 1620975363,
        detail: {
          product_id: 33,
          product_intro: "",
          inout_rule: "",
          earnings_rule: "",
          product_code: "--",
          strategy: "",
          ico_url:
            "https://top6688.zendesk.com/hc/zh-cn/articles/4402147339921-%E8%AE%A4%E8%AF%86-O3",
          ico_eg: "",
          buy_rule: "--",
          risk_tip: "--",
          join_rule: "",
          i18n_lang:
            "ico_url_31,ico_eg_33,buy_rule_31,risk_tip_31,join_rule_0,product_code_33",
        },
        item_name: "USDT",
        item_url: require("../../assets/img/holder-coin-ico.png"),
        to_item_url: require("../../assets/img/holder-coin-ico.png"),
        to_item_name: "--",
        end_time: 1621580400,
        user_profit: 1,
        show_profit: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
    heightClass() {
      return "active" + this.active;
    },
  },
  async created() {
    await this.getProductlist(1);
    await this.getAssets();
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  async mounted() {
    if (this.$route.query.relation_id && this.$route.query.relation_id != 0) {
      let params = {
        product_id: this.$route.query.relation_id,
      };
      console.log(params);

      const { status, data } = await apiGetProductInfo(params);

      if (status == 200) {
        this.onTypeChange(data.productInfo.product_type);
        this.productId = this.$route.query.relation_id;
        this.financialDialogVisible = true;
      }
      // apiGetProductInfo(params).then((res) => {
      //   if (res.status == 200) {
      //     // this.onTypeChange(res.data.productInfo.product_type);
      //     // this.productId = this.$route.query.relation_id;
      //     // this.financialDialogVisible = true;
      //   }
      // });
    }
  },
  methods: {
    routeSplicing,
    timestampToDate,
    translateTitle,
    remainingPercent(remaining, amount) {
      let tempVal = floorNumber((remaining * 100) / amount, 2);
      return tempVal;
    },
    onLockPeriodChange(prod, item) {
      item.active_lock_period_id = prod.product_id;
      item.active_annual_rate = prod.annual_rate;
    },
    goToMyHolder() {
      if (!this.userInfo) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: { redirect: this.$route.fullPath },
        });
      } else {
        this.$router.push(
          this.routeSplicing(this.language, "ucenter/finanManagement")
        );
      }
    },
    annualRateComputed(annual_rate) {
      let tempRate = new BigNumber(annual_rate);
      let time = new BigNumber(100);
      return tempRate.times(time).toString();
    },
    onCountDownEnd() {
      this.getProductlist();
      // setTimeout(() => {
      //   this.getProductlist();
      // }, 1500);
    },
    onProductInfoChange(productInfo) {
      this.productInfo = productInfo;
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "remain",
      };
      const { status, data } = await apiGetAssets(params);
      if (status == 200) {
        let tempDate = data.remain.list;
        tempDate.forEach(ele => {
          if (ele.item_id == 10007) {
            this.apiAssetsInfo = ele.over;
          }
        });
      }
    },
    onDialogClose() {
      this.financialDialogVisible = false;
    },
    onConfirm() {
      this.confirmDialogVisible = true;
    },
    onConfirmCancel() {
      this.confirmDialogVisible = false;
    },
    onSuccess() {
      this.successDialogVisible = false;
      this.getProductlist();
    },
    onConfirmOK() {
      this.confirmDialogVisible = false;
      this.financialDialogVisible = false;
      this.successDialogVisible = true;
    },
    onClickAll() {
      this.buyNumber = this.apiAssetsInfo;
    },

    // 选项卡切换
    onTypeChange(val) {
      this.active = val;
      if (val == 3) {
        this.confirmDialogTitle = this.translateTitle(`量化理财`);
      } else if (val == 2) {
        this.confirmDialogTitle = this.translateTitle(`活期存入`);
      } else if (val == 1) {
        this.confirmDialogTitle = this.translateTitle(`定期存入`);
      } else if (val == 4) {
        this.confirmDialogTitle = "Launchpad";
      }
      this.getProductlist();
    },
    async goToBuy(item) {
      if (item.union_info.length > 0) {
        this.productId = item.active_lock_period_id;
      } else {
        this.productId = item.product_id;
      }
      this.financialDialogVisible = true;
    },
    goToUseCenter() {
      this.$router.push({
        path: this.routeSplicing(this.language, "ucenter/finanManagement"),
      });
    },
    getProductlist(timer) {
      let tempProductType = 0;
      if (this.active == 3) {
        tempProductType = 3;
      } else if (this.active == 2) {
        tempProductType = 2;
      } else if (this.active == 1) {
        tempProductType = 1;
      } else if (this.active == 4) {
        tempProductType = 4;
      }
      let params = {
        item_type: 1, //货币类型，1为数字货币，2为法币
        product_type: tempProductType, //产品类型，1定期 2活期 3量化 4汇信池，-1为全部产品列表
        page: 1,
        page_size: 100,
      };
      apiGetProductListV2(params).then(res => {
        if (res.status == 200) {
          let tempList = res.data.list;
          const now = Date.parse(new Date());
          tempList.forEach(ele => {
            this.productTypeList.push(ele.product_type);

            ele.to_item_id_price = Number(ele.to_item_id_price);
            if (ele.to_item_url == "") {
              ele.to_item_url = require("../../assets/img/holder-coin-ico.png");
            }
            if (
              ele.stop_time != 0 &&
              now < ele.stop_time * 1000 &&
              ele.stop_time * 1000 - now < 86400000
            ) {
              ele.remain_end_time = ele.stop_time * 1000 - now;
            }
            if (ele.start_time != 0) {
              ele.remain_time = ele.start_time * 1000 - now;
            } else {
              ele.timeCount = 0;
            }
            if (ele.union_info.length > 0) {
              ele.active_lock_period_id = ele.union_info[0].product_id;
              ele.active_annual_rate = ele.union_info[0].annual_rate;
            }
          });
          if (timer) {
            if (this.productTypeList.indexOf(3) > -1) {
              this.listType.push({ text: "量化理财", val: 3 });
            }
            if (this.productTypeList.indexOf(2) > -1) {
              this.listType.push({ text: "活期", val: 2 });
            }
            if (this.productTypeList.indexOf(1) > -1) {
              this.listType.push({ text: "定期", val: 1 });
            }

            if (this.productTypeList.indexOf(4) > -1) {
              this.listType.push({ text: "Launchpad", val: 4 });
            }
          }
          this.dataList = tempList;
        }
        this.pageLoading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.finances-managerment {
  width: 100%;
  min-height: calc(100vh - 281px);
  background-color: #ffffff;
  .finance-dialog {
    width: 1000px;
    border-radius: 8px;
    // height: 680px;
    &.active1 {
      min-height: 475px;
      overflow: hidden;
      .el-dialog__body {
        min-height: 475px;
      }
    }
    &.active3 {
      min-height: 651px;
      .el-dialog__body {
        min-height: 651px;
      }
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      display: flex;
      border-radius: 8px;
      justify-content: space-between;
    }
  }
  .header {
    width: 100%;
    height: 253px;
    // opacity: 0.61;
    // background: black;
    // background-image: url("../../assets/img/finances-bg.png");
    background: url("../../assets/svg/bg.svg") 0 0 repeat;
    // background-repeat: round;
    // background-size: 100% 100%;
    .header-content {
      user-select: none;
      height: 253px;
      width: 1200px;
      margin: 0px auto;
      padding-top: 80px;
      .first-item {
        // width: 160px;
        height: 56px;
        font-size: 40px;
        font-weight: 500;
        margin: 0 102px 11px 0;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
      }
      .second-item {
        // width: 562px;
        height: 15px;
        margin: 11px 0 0;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.94;
        letter-spacing: normal;
        color: #ffffff;
      }
    }
  }
  .list-content {
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    .type-list {
      width: 1200px;
      height: 60px;
      line-height: 60px;
      margin: 0px auto;
      position: relative;
      .my-holder {
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 0;
        font-size: 16px;
        font-weight: 400;
        color: #354052;
        span {
          margin-left: 2px;
          opacity: 0.6;
          color: #9aa1b1;
        }
      }
      .list-type {
        display: flex;
        margin-bottom: 20px;
        user-select: none;
        .tab {
          position: relative;
          margin-right: 22px;
          color: #7f8490;
          line-height: 60px;
          cursor: pointer;
          span {
            font-size: 16px;
            font-weight: 400;
          }
          &:hover {
            color: #354052;
            &:after {
              content: "";
              height: 2px;
              width: 80%;
              background-color: #c61b3d;
            }
          }
          &.active {
            span {
              color: #354052;
              font-size: 20px;
              font-weight: 500;
            }
            &:after {
              content: "";
              height: 3px;
              width: 20px;
              background-color: #c61b3d;
              border-radius: 1.5px;
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .finances-content {
    width: 1200px;
    margin: 0px auto;
    padding: 30px 0;
    min-height: 783px;
    .el-loading-mask {
      min-height: 200px;
    }

    .finances-item {
      user-select: none;
      width: 285px;
      height: 315px;
      margin: 0px 20px 20px 0;
      padding: 20px;
      border-radius: 4px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      cursor: pointer;
      border: 1px solid white;
      display: inline-block;
      transition: all 0.5s;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        border: solid 1px #3a7dff;
      }
      &.status_8,
      &.status_6,
      &.status_7 {
        .title {
          color: #9aa1b1;
        }
        .center-content .number-item,
        .text-item,
        .date-item {
          color: #9aa1b1 !important;
        }
      }
      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 193px;
        margin-top: -3px;
        display: inline-block;
        vertical-align: middle;
      }
      img {
        width: 28px;
        height: 28px;
        margin: 0 7px 3px 0;
        vertical-align: middle;
      }

      .type-content {
        margin-left: 32px;
        width: 211px;
        height: 22px;
        // .type-6,
        // .type-5
        .type-progress {
          // margin: 3px 0px 36px 7px;
          // padding: 2.2px 5.1px 1.8px 5.6px;
          // border-top-left-radius: 5px;
          // border-bottom-right-radius: 5px;
          // font-size: 12px;
          // color: white;
          &.V2 {
            width: fit-content;
          }
          .countdown {
            width: fit-content;
            height: 20px;
            margin: 3px 0px 36px 7px;
            padding: 2.2px 5.1px 1.8px 5.6px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            font-size: 12px;
            color: white;
            background-image: linear-gradient(
              to right,
              #f78700 0%,
              #ffb152 100%
            );
            display: inline-block;
          }
          .progress-count {
            background-image: linear-gradient(
              to right,
              #ff486d 0%,
              #c61b3d 100%
            );
          }
        }
        .type-1,
        .type-5 {
          display: inline-block;
          height: 20px;
          margin: 3px 5px 36px 4px;
          padding: 2.2px 5.1px 1.8px 5.6px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-image: linear-gradient(to right, #f7ad00 0%, #ffd452 100%);
          font-size: 12px;
          color: white;
          &.V2 {
            width: fit-content;
          }
        }
        .type-6,
        .type-8,
        .type-4,
        .type-7 {
          width: 58px;
          height: 20px;
          margin: 3px 0px 36px 7px;
          padding: 2.2px 5.1px 1.8px 5.6px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-color: #c2c7d0;
          font-size: 12px;
          color: white;
        }
        .quant-type-1 {
          display: inline-block;
          padding: 2.2px 4.4px 1.8px 5.6px;
          width: fit-content;
          height: 20px;
          border-top-left-radius: 5px;
          font-size: 12px;
          color: white;
          border-bottom-right-radius: 5px;
          background-image: linear-gradient(265deg, #8871ff 86%, #735af2 0%);
        }
        .quant-type-2 {
          display: inline-block;
          padding: 2.2px 7px 1.8px 7px;
          width: fit-content;
          height: 20px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-image: linear-gradient(266deg, #579fff 35%, #0068f3 120%);
          font-size: 12px;
          color: white;
        }
        .quant-type-3 {
          display: inline-block;
          padding: 2px 5px;
          width: 50px;
          height: 20px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
          font-size: 12px;
          color: white;
        }
        .type-red {
          width: 50px;
          height: 20px;
          line-height: 17px;
          display: inline-block;
          box-sizing: border-box;
          margin-left: 5px;
          margin-top: -2px;
          vertical-align: middle;
          padding: 2.2px 4.6px 1.8px 5.6px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 0;
          border-bottom-left-radius: 0;
          font-size: 12px;
          color: white;
        }
        .type-3 {
          width: fit-content;
          height: 20px;
          margin: 3px 0px 36px 7px;
          padding: 2.2px 5.1px 1.8px 4.1px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
          font-size: 12px;
          color: white;
        }

        .type-2 {
          width: 44px;
          height: 20px;
          nav-right: 5px;
          padding: 2.2px 4.4px 1.8px 5.6px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          background-image: linear-gradient(266deg, #579fff 35%, #0068f3 120%);
          font-size: 12px;
          color: white;
        }
      }
      .center-content {
        margin-top: 20px;
        text-align: center;
        &.V2 {
          margin-top: 20px;
        }
        .lock-period-content {
          width: fit-content;
          height: 28px;
          margin: 2px auto 3px;
          border-radius: 3px;
          border: solid 1px #c2c7d0;
          .lock-period {
            display: inline-block;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            padding: 0px 16px;
            border-radius: 3px;
            &.active {
              border: solid 1px #c61b3d;
              padding: 0px 14px;
              margin-top: -1px;
              background-color: rgba(198, 27, 61, 0.1);
            }
          }
          .lock-period:first-child {
            &.active {
              margin-left: -1px;
            }
          }
          .lock-period:last-child {
            &.active {
              margin-right: -1px;
            }
          }
        }
        span {
          display: block;
        }
        .number-item {
          font-size: 36px;
          font-weight: bold;
          color: #f04a5a;
        }
        .text-item {
          font-size: 14px;
          color: #354052;
          margin-bottom: 10px;
          &.V2 {
            margin-top: 4px;
            color: #9aa1b1;
            display: block;
            height: 20px;
          }
        }
        .date-item {
          font-size: 18px;
          font-weight: 500;
          color: #354052;
          // margin-top: 12px;
          height: 38px;
          line-height: 38px;
        }
        .btn {
          width: 200px;
          margin: 7px auto 20px;
          cursor: pointer;
        }
        .disabled-btn {
          cursor: pointer;
          width: 200px;
          margin: 7px auto 20px;
          background-color: #c2c7d0;
          background-image: none;
        }
        .progess-btn {
          cursor: pointer;
          width: 200px;
          margin: 7px auto 20px;
          // background-color: #c2c7d0;
          background-image: linear-gradient(to right, #f78700 0%, #ffb152 100%);
        }
      }
    }
  }
  .no-data-box {
    min-height: calc(100vh - 650px) !important;
    .no-data-content {
      text-align: center;
      .no-data-text {
        margin-top: 20px;
        display: block;
      }
    }
  }
  .confirm-dialog {
    user-select: none;
    .el-dialog__header {
      padding: 20px 30px 5px;
      .el-dialog__title {
        font-size: 20px;
        font-weight: 500;
        color: #354052;
      }
    }
    .el-dialog__body {
      padding: 20px 30px;
    }
  }
  .success-dialog {
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #354052;
      line-height: 28px;
      .dialog-content {
        img {
          vertical-align: middle;
          width: 28px;
          height: 28px;
          display: inline-block;
        }
        p {
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
