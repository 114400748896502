import store from "@/store";
import Cookies from "js-cookie";
// 未登录可以访问的页面
const whiteList = ["/", "/login", "/register", "/login/mobile"];
const notLoginList = [
  "/login",
  "/ucenter/assets",
  "/ucenter/security-setting",
  "/ucenter/situation",
  "/ucenter/finanManagement",
  "/ucenter/spread-center",
  "/ucenter/coupon-center",
  "/ucenter/safe-setting",
];
export default function(router) {
  router.beforeEach((to, from, next) => {
    // 白名单不做登录验证
    if (whiteList.indexOf(to.path) != -1) {
      next();
    } else {
      // 如果未登录跳转登录页面
      // const token = window.$.cookie('topcredit_exchange_t')

      // if (!token) {
      //   router.push('/login');
      //   next();
      // }
      // 校验是否有token

      let temmToken = Cookies.get("topcredit_exchange_t");
      if (temmToken) {
        store.dispatch("user/getUserInfo");
      } else {
        if (notLoginList.indexOf(to.path) == -1) {
          next();
        } else {
          next({
            path: "/login",
          });
        }
      }
      next();
    }
  });
}
