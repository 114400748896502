<template>
  <div style="min-height:100vh;">
    <SituationInner v-if="defaultPage"></SituationInner>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import SituationInner from "@/pages/ucenter/situation-inner.vue";
export default {
  mixins: [],

  data() {
    return {
      defaultPage: true,
    };
  },
  components: { SituationInner },
  computed: {},
  created() {},
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
