import api from "@/assets/js/api";
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;
import {
  VUE_APP_UCENTER,
  VUE_APP_SOTC,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

//获取账单列表-资产记录
export function apiGetAssetsLog(data = {}) {
  return api({
    url: VUE_APP_SOTC + `/SecretOtc/App/getAssetsLog`,
    data,
    type: "POST",
  });
}
//U本位合约仓位详情
export function apiGetContractPositionInfo(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/Contract/positionInfo`,
    data,
    type: "POST",
  });
}
//币本位合约仓位详情
export function apiGetFuturesPositionInfo(data = {}) {
  return api({
    url: VUE_APP_APP + `/Futures/Common/positionInfo`,
    data,
    type: "POST",
  });
}

//用户获取订单详情-OTC 充值 提现
export function apiGetUserOrderInfo(data = {}) {
  return api({
    url: VUE_APP_SOTC + `/SecretOtc/App/getUserOrderInfo`,
    data,
    type: "POST",
  });
}
