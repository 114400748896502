<template>
  <div></div>
</template>

<script>
import { VUE_APP_ID } from "@/assets/js/stationSetting";
export default {
  components: {},

  data() {
    return {
      VUE_APP_ID,
    };
  },
  created() {},
};
</script>

<style></style>
