<template>
  <div class="table-empty">
    <img draggable="false" src="./../assets/img/table-empty@2x.png" alt="" />
    <span class="empty-text">{{
      text ? text : translateTitle("暂无数据")
    }}</span>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  mixins: [commonMixin],
  props: ["text"],
};
</script>
<style lang="scss" scoped>
.table-empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 500px;
  max-width: 976px;
  img {
    width: 80px;
    margin-top: 70px;
  }

  .empty-text {
    color: #909399;
    line-height: 1;
    font-size: 14px;
    margin-top: 13px;
    font-weight: normal;
  }
}
</style>
