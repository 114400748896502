<template>
  <div>
    <SecuritySettingInner v-if="defaultPage"></SecuritySettingInner>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import SecuritySettingInner from "@/pages/ucenter/security-setting-inner.vue";
export default {
  mixins: [],

  data() {
    return {
      defaultPage: true,
    };
  },
  components: { SecuritySettingInner },
  computed: {},
  created() {},
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
