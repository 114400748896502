<template>
  <div></div>
</template>
<script>
export default {
  mixins: [],
  components: {},

  data() {
    return {};
  },
  async created() {},
  mounted() {
    let me = this;
  },
  destroyed() {},
  beforeDestroy() {},

  watch: {},
};
</script>
<style lang="scss"></style>
