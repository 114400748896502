<template>
  <div class="transaction-managment-content-record-exchange">
    <div class="market-header">
      <ul>
        <li
          class="classify-area"
          :class="{ active: currIndex == item.value }"
          v-for="(item, index) of tabList"
          :key="item.value"
          @click="onAreaChange(item)"
        >
          {{ translateTitle(item.label) }}
          <span v-if="index == 0 && orderNum > 0">({{ orderNum }})</span>
          <i v-if="currIndex == item.value" class="item-bar"></i>
        </li>
        <li class="hidden-btn">
          <el-checkbox v-if="currIndex == 2" v-model="hidenCancelChecked">{{
            translateTitle("隐藏已撤销")
          }}</el-checkbox>
          <!-- <el-checkbox v-model="checked">{{
            translateTitle("隐藏其他交易对")
          }}</el-checkbox> -->
          <!-- 隐藏其他交易对 -->
        </li>
      </ul>
    </div>
    <!-- 变更结束，FIXME -->

    <!-- U本位合约表格开始 -->
    <div style="min-height:630px;">
      <div class="filter-box" v-show="currIndex == 2">
        <div class="tenWid"></div>
        <el-date-picker
          v-model="ctimeAndEtime"
          type="daterange"
          :range-separator="translateTitle('至')"
          :start-placeholder="translateTitle('开始日期')"
          :end-placeholder="translateTitle('结束日期')"
          format="yyyy-MM-dd"
          value-format="timestamp"
        />
        <div class="tenWid"></div>
        <el-select
          v-model="planOrderList_symbol_id"
          value-key="symbol_id"
          :placeholder="translateTitle('请选择交易对')"
          :clearable="true"
          class="selectStyle"
        >
          <el-option
            v-for="item in exchangeCoinMap"
            :key="item.symbol_id"
            :label="item.name.toUpperCase()"
            :value="item.symbol_id"
          >
          </el-option>
        </el-select>
        <div class="tenWid"></div>
        <el-select
          v-model="planOrderList_open_side"
          value-key="value"
          :placeholder="translateTitle('请选择方向')"
          :clearable="true"
          class="selectStyle"
        >
          <el-option
            v-for="item in open_side_array"
            :key="item.value"
            :label="translateTitle(item.label)"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <div class="twentyWid"></div>
        <span class="btn-search" @click="searchPlanOrderList">{{
          translateTitle("查询")
        }}</span>
        <div class="tenWid"></div>
        <span class="btn-reset" @click="resetPlanOrderList">{{
          translateTitle("重置")
        }}</span>
      </div>

      <el-table
        :data="tableData"
        v-loading="orderLoading"
        height="570"
        style="width: 100%"
      >
        <el-table-column
          prop="trust_id"
          align="center"
          :label="translateTitle('订单ID')"
          key="111"
          min-width="90"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">{{ row.trust_id }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex == 1"
          prop="create_time"
          align="center"
          :label="translateTitle('下单时间')"
          key="1"
          min-width="140"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">{{ parseTime(row.create_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex == 2"
          prop="create_time"
          align="center"
          :label="translateTitle('下单时间')"
          key="10"
          min-width="145"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">{{ parseTime(row.create_time) }}</span>
          </template>
        </el-table-column>

        <el-table-column
          key="2"
          align="center"
          prop="symbol_name"
          :label="translateTitle('交易对')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span class="DINPro symbol_name_ano" @click="onSymbolClick(row)">{{
              row.symbol_name
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
        align="center"
        prop="trade_type"
        label="类型"
        min-width="130"
      >
        <template slot-scope="{ row }">
          <span>{{ tradeMap[row.trade_type] }}</span>
        </template>
      </el-table-column> -->
        <el-table-column
          align="center"
          prop="side"
          key="3"
          :label="translateTitle('方向')"
          min-width="90"
        >
          <template slot-scope="{ row }">
            <!-- <span :class="'side_' + (row.side ? 1 : 2)">{{
              sideMap[row.side]
            }}</span> -->
            <span :class="'side_' + (row.side == 'buy' ? 1 : 2)">{{
              sideMap[row.side]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex != 3"
          align="right"
          prop="price"
          key="4"
          :label="translateTitle('委托价格')"
          min-width="90"
        >
          <!-- :label="`委托价(${to_symbol.toUpperCase()})`" -->
          <template slot-scope="{ row }">
            <div v-if="row.type == 1">
              <!-- <span v-if="row.side == 1">≥</span>
              <span v-if="row.side == 2">≤</span> -->
              <span class="DINPro">{{ row.price }}</span>
            </div>
            <div v-else>
              <span>{{ translateTitle("市价") }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex != 1"
          key="5"
          align="right"
          prop="deal_avg_price"
          :label="translateTitle('成交均价')"
          min-width="130"
        >
          <!-- :label="`成交均价(${to_symbol.toUpperCase()})`" -->
          <template slot-scope="{ row }">
            <span class="DINPro" v-if="row.status == 3">--</span>
            <span class="DINPro" v-else>{{ row.deal_avg_price }}</span>
          </template>
        </el-table-column>
        <el-table-column
          key="6"
          align="right"
          prop="deal_num"
          :label="translateTitle('委托数量')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span>
              <span v-if="currIndex == 1" class="DINPro"
                >{{ row.num }} {{ row.coin_name }}</span
              >
              <!-- {{ row.coin_name }} -->
              <span v-else-if="currIndex == 2" class="DINPro"
                >{{ row.deal_num }} {{ row.coin_name }}
              </span>
            </span>
            <!-- {{ row.coin_name }} -->
          </template>
        </el-table-column>
        <el-table-column
          key="7"
          prop="total"
          align="right"
          min-width="130"
          :label="translateTitle('委托总额')"
        >
          <!-- :label="`委托总额(${to_symbol.toUpperCase()})`" -->
          <template slot-scope="{ row }">
            <span class="DINPro">{{ row.total }} {{ row.coin_to_name }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="address" label="已成交" min-width="130">
      </el-table-column>
      <el-table-column prop="address" label="未成交" min-width="130">
      </el-table-column> -->
        <el-table-column
          v-if="currIndex != 1"
          align="right"
          key="9"
          prop="fee"
          :label="translateTitle('手续费')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span class="DINPro" v-if="row.status == 3">--</span>
            <span class="DINPro" v-else
              >{{ row.fee
              }}<span v-if="row.side == 'buy'">
                {{ row.coin_name }}
              </span>
              <span v-else>{{ row.coin_to_name }}</span></span
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex != 1"
          align="center"
          key="8"
          prop="status"
          :label="translateTitle('状态')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span :class="'status_' + row.status">{{
              translateTitle(statusMap[row.status])
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex == 2"
          prop="update_time"
          align="center"
          :label="translateTitle('完成时间')"
          key="11"
          min-width="150"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">{{ parseTime(row.update_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex == 1"
          :label="translateTitle('操作')"
          key="9"
          align="center"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span @click="onOpenConfirm(row.trust_id)" class="operation">{{
              translateTitle("撤销")
            }}</span>
          </template>
        </el-table-column>
        <no-data
          v-show="!orderLoading && tableData.length == 0"
          slot="empty"
        ></no-data>
      </el-table>
    </div>
    <div class="common-table-pages" v-show="currIndex == 1"></div>
    <!-- <div
      class="common-table-pages"
      v-show="tableData.length > 0 && currIndex != 1"
    > -->
    <div class="common-table-pages" v-show="currIndex != 1">
      <p>
        {{ translateTitle("每页") }}
        <span>{{ pageInfo.pageSize }}</span>
        {{ translateTitle(`条，总共`) }}
        <span>{{ pageInfo.pageCount }}</span>
        {{ translateTitle("页") }}
      </p>
      <el-pagination
        class="mt20"
        background
        layout="prev, pager, next"
        :total="pageInfo.pageTotal"
        :page-size="pageInfo.pageSize"
        :current-page.sync="pageInfo.pageCurrent"
        @current-change="onCurrentPageChange"
      ></el-pagination>
    </div>

    <!-- <el-pagination
      v-if="tableData.length > 0 && currIndex != 1"
      :current-page.sync="pageInfo.pageCurrent"
      :layout="layout"
      :page-size="pageInfo.pageSize"
      :total="pageInfo.pageTotal"
      background
      @current-change="onCurrentPageChange"
    /> -->
    <el-dialog
      :title="translateTitle('操作提示')"
      custom-class="confirm-dialog"
      :visible.sync="dialogVisible"
      :lock-scroll="false"
      width="440px"
    >
      <span style="font-size: 16px">{{
        translateTitle("确认撤销此订单？")
      }}</span>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="dialogVisible = false">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="onOK">{{
          translateTitle("确认")
        }}</span>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  apiGetOrderList,
  apiTrustCancel,
  apiTrustIndex,
  apiTrustDealIndex,
} from "@/model/exchange/order.js";
import NoData from "@/components/common/NoData";
import { parseTime } from "@/utils/index.js";
import { mapActions, mapGetters } from "vuex";
import { showMessage } from "@/components/dialog/message.js";
import * as Account from "@/model/user/account";
import { apiGetStaticTableInfo } from "@/model/settings";

import { translateTitle } from "@/assets/js/i18n";
import commonMixin from "@/components/common/commonMixin.vue";
import { apiWsUrl, apiGetDefaultToken } from "@/model/contractExchange/index";
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import Cookies from "js-cookie";
import Socket from "@/assets/js/socket.js";
import { getStaticCoinList as getCoinList } from "@/model/assets/index";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  // props: {
  //   marketList: {
  //     type: Array,
  //     default: function() {
  //       return [];
  //     },
  //   },
  //   exchangeCoinMap: {
  //     type: Object,
  //     default: function() {
  //       return {};
  //     },
  //   },
  //   swap_symbol_id: {
  //     type: [String, Number],
  //     default: function() {
  //       return 22;
  //     },
  //   },
  //   from_symbol: {
  //     type: [String, Number],
  //   },
  //   to_symbol: {
  //     type: [String, Number],
  //   },
  // },
  mixins: [commonMixin],
  components: {
    NoData,
  },
  data() {
    return {
      planOrderList_symbol_id: "",
      planOrderList_open_side: "",
      ctimeAndEtime: [],

      open_side_array: [
        {
          value: "buy",
          label: "买入",
        },
        {
          value: "sell",
          label: "卖出",
        },
      ],

      marketList: [],
      exchangeCoinMap: {},
      swap_symbol_id: 22,
      from_symbol: "",
      to_symbol: "",

      parseTime,
      ws: null,
      tableData: [],
      tableDataComputed: [],
      orderLoading: true,
      dialogVisible: false,
      hidenCancelChecked: false,
      checked: false,
      layout: "total, prev, pager, next",
      symbolId: "",
      trust_id: "",
      orderNum: 0,
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      },
      tabList: [
        {
          value: "1",
          label: "当前委托",
        },
        {
          value: "2",
          label: "历史委托",
        },
        // {
        //   value: "3",
        //   label: "成交明细",
        // },
      ],
      currIndex: "1",
      coinItemIdMap: {},
      tradeMap: {
        1: this.translateTitle("市价"),
        2: this.translateTitle("限价"),
      },
      sideMap: {
        buy: this.translateTitle("买入"),
        sell: this.translateTitle("卖出"),
      },
      statusMap: {
        0: this.translateTitle("未成交"),
        1: this.translateTitle("部分成交"),
        2: this.translateTitle("完全成交"),
        3: this.translateTitle("已撤销"),
      },
    };
  },

  async created() {
    await this.getExchangeCoinMap();
    await this.getExchangeItemId();
    let { userInfo } = await Account.getUserInfo();
    if (userInfo) {
      this.initData(this.checked);
      // this.startTimer();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
    tableHeight() {
      let tempHeight = 300;
      tempHeight = this.currIndex == 1 ? 360 : 300;
      return tempHeight;
    },
  },
  methods: {
    routeSplicing,
    async getExchangeCoinMap() {
      const { status, data } = await apiGetStaticTableInfo(55);
      if (status == 200) {
        this.exchangeCoinMap = data;
      }
    },
    searchPlanOrderList() {
      this.pageInfo.pageCurrent = 1;
      this.initData();
    },
    resetPlanOrderList() {
      this.pageInfo.pageCurrent = 1;
      this.planOrderList_symbol_id = "";
      this.planOrderList_open_side = "";
      this.ctimeAndEtime = [];
      this.initData();
    },
    onOK() {
      this.onCancelOrder();
      this.dialogVisible = false;
    },
    onOpenConfirm(id) {
      this.trust_id = id;
      this.dialogVisible = true;
    },
    async getExchangeItemId() {
      const { status, data } = await apiGetStaticTableInfo(13);
      if (status == 200) {
        this.coinItemIdMap = JSON.parse(JSON.stringify(data));
      }
    },
    reset(type) {
      this.orderLoading = true;
      this.currIndex = type.toString();
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
    },
    onSymbolChange(item) {
      this.$emit("on-symbol-change", item);
    },
    onSymbolClick(row) {
      window.open(
        this.routeSplicing(
          this.language,
          "exchange/" + row.symbol_name_v2.toLowerCase()
        )
      );
    },
    async startTimer() {
      const timer = setTimeout(() => {
        this.initData(this.checked);
        this.startTimer();
      }, 3000);
      this.$once("hook:beforeDestroy", () => {
        clearTimeout(timer);
      });
    },
    async onCancelOrder() {
      const req = {
        trust_id: this.trust_id,
      };
      const { status, data } = await apiTrustCancel(req);
      if (status == 200) {
        showMessage({
          message: this.translateTitle("撤销成功"),
          type: "success",
        });
        this.initData(this.checked);
      }
    },
    onAreaChange(item) {
      this.orderLoading = true;
      this.tableData = [];
      this.currIndex = item.value;
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
    },
    async initData(checked = false) {
      this.orderLoading = true;
      let { userInfo } = await Account.getUserInfo();
      if (!userInfo) {
        this.orderLoading = false;
        return;
      }
      let tempStatus = this.currIndex;
      if (this.currIndex == 3) {
        tempStatus = "";
      }
      let temp_symbol_id = this.swap_symbol_id;
      if (checked) {
        temp_symbol_id = this.swap_symbol_id;
      } else {
        temp_symbol_id = "";
      }

      if (this.currIndex == 1) {
        const req = {
          symbol_id: temp_symbol_id,
        };

        return apiTrustIndex(req).then(res => {
          if (res.status == 200 && res.data.length > 0) {
            this.orderNum = res.data.length;
            for (const key in this.exchangeCoinMap) {
              res.data.forEach(ele => {
                if (ele.symbol_id == key) {
                  ele.symbol_name_v2 = this.exchangeCoinMap[
                    key
                  ].name.toUpperCase();
                }
              });
            }
            for (const key in this.coinItemIdMap) {
              res.data.forEach(ele => {
                if (ele.coin_from == key) {
                  ele.coin_name = this.coinItemIdMap[
                    key
                  ].item_name.toUpperCase();
                }
                if (ele.coin_to == key) {
                  ele.coin_to_name = this.coinItemIdMap[
                    key
                  ].item_name.toUpperCase();
                }
              });
            }
            this.tableData = res.data;
          } else {
            this.tableData = [];
            this.orderNum = 0;
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 2) {
        let temp_order_status = "1,2,3";
        if (this.hidenCancelChecked) {
          temp_order_status = "1,2";
        } else {
          temp_order_status = "1,2,3";
        }
        let create_time = "";
        let end_time = "";
        if (this.ctimeAndEtime) {
          const [start, end] = this.ctimeAndEtime;
          const end2 = end + 86399000;
          create_time = parseInt(start / 1000);
          end_time = parseInt(end2 / 1000);
        }
        const req = {
          symbol_id: this.planOrderList_symbol_id,
          side: this.planOrderList_open_side,
          start_time: create_time,
          end_time: end_time,
          status: temp_order_status,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize,
        };
        return apiTrustDealIndex(req).then(res => {
          if (res.status == 200) {
            for (const key in this.exchangeCoinMap) {
              res.data.list.forEach(ele => {
                if (ele.symbol_id == key) {
                  ele.symbol_name_v2 = this.exchangeCoinMap[
                    key
                  ].name.toUpperCase();
                }
              });
            }
            for (const key in this.coinItemIdMap) {
              res.data.list.forEach(ele => {
                if (ele.fee_item_id == key) {
                  ele.fee_item_name = this.coinItemIdMap[
                    key
                  ].item_name.toUpperCase();
                }
                if (ele.coin_from == key) {
                  ele.coin_name = this.coinItemIdMap[
                    key
                  ].item_name.toUpperCase();
                }
                if (ele.coin_to == key) {
                  ele.coin_to_name = this.coinItemIdMap[
                    key
                  ].item_name.toUpperCase();
                }
              });
            }
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      }
    },
    onCurrentPageChange() {
      this.initData(this.checked);
    },
  },
  watch: {
    language() {
      this.tradeMap = {
        1: this.translateTitle("市价"),
        2: this.translateTitle("限价"),
      };
      this.sideMap = {
        buy: this.translateTitle("买入"),
        sell: this.translateTitle("卖出"),
      };
      this.statusMap = {
        0: this.translateTitle("未成交"),
        1: this.translateTitle("待成交"),
        2: this.translateTitle("完全成交"),
        3: this.translateTitle("已撤销"),
      };
    },
    swap_symbol_id: {
      handler(newVal, oldVal) {
        if (oldVal == undefined) {
          return;
        }
        if (newVal != oldVal) {
          this.symbolId = newVal;
          this.orderLoading = true;
          this.pageInfo.pageCurrent = 1;
          this.initData(this.checked);
        }
      },
    },
    hidenCancelChecked() {
      this.initData(this.checked);
    },
    checked() {
      this.initData(this.checked);
    },
  },

  computed: {
    // showList() {
    //   return this.marketList;
    // },
  },
};
</script>
<style lang="scss">
.transaction-managment-content-record-exchange {
  padding: 0px 20px;
  background: #ffffff;
  overflow: hidden;
  margin: 20px;
  min-height: 600px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  .filter-box {
    margin: 0px 0px 20px 0px;
    .tenWid {
      width: 10px;
      height: 1px;
      display: inline-block;
      position: relative;
    }
    .twentyWid {
      width: 20px;
      height: 1px;
      display: inline-block;
      position: relative;
    }
    .btn-search {
      user-select: none;
      cursor: pointer;
      width: 120px;
      height: 16px;
      padding: 10px 21px;
      border-radius: 3px;
      background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
      color: #fff;
      font-size: 14px;
    }
    .btn-reset {
      user-select: none;
      cursor: pointer;
      width: 120px;
      height: 16px;
      padding: 9px 20px;
      border-radius: 3px;
      color: #354052;
      font-size: 14px;
      border: solid 1px #c2c7d0;
      box-sizing: border-box;
    }
    .btn-export {
      user-select: none;
      cursor: pointer;
      /* width: 120px; */
      padding: 10px 12px;
      border-radius: 3px;
      color: #354052;
      font-size: 14px;
      border: solid 1px #c2c7d0;
      box-sizing: border-box;
      float: right;
      .export-icon {
        color: #9aa1b1;
        margin-right: 4px;
      }
    }
    .el-input {
      width: 100px;

      .el-input__inner {
        border: solid 1px #e2e2e4;
        box-sizing: border-box;
        border-radius: 3px;
      }
    }
    .el-checkbox {
      margin-left: 20px;
      .el-checkbox__label {
        font-size: 14px;
        color: #354052;
        font-weight: 400;
      }
    }
    .selectStyle {
      width: 180px;
      .el-input.el-input--suffix {
        width: 100%;
      }
    }
  }
  .open-item-img {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    margin-top: -12.5px;
    left: 13%;
  }
  .symbol_name {
    margin-left: 55px;
    font-size: 16px;
    font-weight: 500;
    color: #354052;
  }
  .open-item-img {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    margin-top: -12.5px;
    left: 13%;
  }
  .symbol_name {
    margin-left: 55px;
    font-size: 16px;
    font-weight: 500;
    color: #354052;
  }
  .symbol_name_ano {
    font-size: 14px;
    font-weight: 500;
    color: #3a7dff;
    cursor: pointer;
  }
  .open-item-img {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    margin-top: -12.5px;
    left: 13%;
  }
  .market-header {
    border-bottom: solid 1px #ecedf1;
    margin-bottom: 20px;
    ul {
      display: flex;
    }
    .classify-area {
      height: 40px;
      margin-top: 10px;
      font-size: 16px;
      font-weight: 500;
      color: #354052;

      padding: 0 15px;
      cursor: pointer;
      text-align: center;
      line-height: 32px;
      font-weight: normal;
      position: relative;
      user-select: none;
      &.active {
        font-weight: 500;
      }
      .item-bar {
        position: absolute;
        width: calc(100% - 30px);
        height: 3px;
        background-color: #e81b40;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
    }
    .hidden-btn {
      margin: 0 20px 0 auto;
      font-size: 14px;
      color: #354052;
      display: flex;
      align-items: center;
      .el-checkbox__label {
        font-size: 14px;
        font-weight: normal;
      }
    }
    .header-cancel-btn {
      margin: 4px 15px;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      .cancel-btn-v2 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        // width: 52px;
        height: 24px;
        padding: 1px 5px 1px;
        // line-height: 24px;
        border-radius: 2px;
        border: solid 1px #e2e2e4;
        background-color: #ffffff;
        color: #3a7dff;
        border-color: #3a7dff;
        font-size: 14px;
        line-height: 12px;
        font-weight: 500;
        outline: none;
        &:hover {
          background-color: #ffffff;
          color: #3a7dff;
          border: solid 1px #e2e2e4;
          outline: none;
        }
        &:focus {
          background-color: #ffffff;
          color: #3a7dff;
          border: solid 1px #e2e2e4;
          outline: none;
        }
      }
    }
  }
  .content-header {
    width: 120px;
    height: 40px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #354052;
  }
  .el-table {
    // height: calc(100vh - 692px);
    /* min-height: 624px; */
    min-height: 570px;
  }
  .no-data-box {
    /* min-height: 600px; */
    min-height: 520px;
  }
  .el-table__row {
    height: 50px !important;
    .cell {
      color: #354052;
      font-size: 14px;
      // display: flex;
      align-items: center;
      .red {
        color: #fa4d56;
      }
      .green {
        color: #03ad79;
      }
    }
  }
  .coin-ing {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
  /* .filter-box {
    padding: 20px 0px 15px 0px;
    .el-input {
      width: 200px;

      .el-input__inner {
        border: solid 1px #e2e2e4;
        box-sizing: border-box;
        border-radius: 3px;
      }
    }
    .el-checkbox {
      margin-left: 20px;
      .el-checkbox__label {
        font-size: 14px;
        color: #354052;
        font-weight: 400;
      }
    }
  } */
  .operation {
    font-size: 14px;
    color: #3a7dff;
    font-weight: 400;
    cursor: pointer;
    padding: 0px 0px 0px 20px;
    /* padding: 0px 20px 0px 0px; */
  }
  .operation-ban {
    font-size: 14px;
    color: #9aa1b1;
    font-weight: 400;
    padding: 0px 0px 0px 20px;
    /* padding: 0px 20px 0px 0px; */
  }
  .append-money-dialog {
    .el-dialog__header {
      padding: 20px 30px 20px;
    }
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      padding: 0px 30px 5px;
    }
    .el-dialog__footer {
      padding: 10px 20px 30px;
    }
    .first-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .available-assets {
        color: #bec1c8;
      }
    }
    .second-content {
      position: relative;
      .el-input__suffix {
        line-height: 40px;
        margin-right: 40px;
        color: #354052;
        .el-input__suffix-inner {
          font-size: 12px;
        }
      }
      .all-btn {
        position: absolute;
        right: 15px;
        top: 12px;
        font-size: 12px;
      }
    }
    .three-content {
      width: 440px;
      height: 120px;
      margin: 20px 30px 20px 0px;
      padding: 14px 15px;
      border-radius: 4px;
      background-color: #f8f8f8;
      .item-one,
      .item-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          display: block;
          margin-bottom: 2px;
          color: #7f8490;
          font-size: 12px;
          &.black {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
          }
          &.red {
            font-weight: 500;
            font-size: 14px;
            color: #f04a5a;
          }
        }
        .left-item {
          width: 150px;
        }
        .right-item {
          width: 150px;
          text-align: right;
        }
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
  .set-profitPrice-dialog {
    .el-dialog__header {
      padding: 20px 30px 5px;
    }
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      padding: 0px 30px 5px;
    }
    .el-dialog__footer {
      padding: 35px 20px 30px;
    }
    .profitPrice-first-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .left-item {
        color: #354052;
        font-size: 16px;
        font-weight: 500;
      }
      .right-item {
        font-size: 12px;
        .green {
          color: #03ad79;
        }
        .red {
          color: #c61b3d;
        }
      }
      &.V2 {
        margin-top: 15px;
      }
    }
    .profitPrice-second-content {
      position: relative;
      .el-input__suffix {
        line-height: 40px;
        margin-right: 40px;
        color: #354052;
      }
      .all-btn {
        position: absolute;
        right: 10px;
        top: 9px;
      }
    }
    .profitPrice-three-content {
      width: 440px;
      height: 70px;
      margin: 15px 0px 15px 0px;
      padding: 14px 15px;
      border-radius: 4px;
      background-color: #f8f8f8;
      .item-one,
      .item-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          display: block;
          margin-bottom: 2px;
          color: #7f8490;
          font-size: 12px;
          &.black {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
          }
          &.red {
            font-size: 14px;
            color: #f04a5a;
          }
        }
        .left-item {
          width: 150px;
        }
        .center-item {
          width: 150px;
          text-align: center;
        }
        .right-item {
          width: 150px;
          text-align: right;
        }
      }
    }
  }
  .confirm-dialog {
    // height: 200px;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      text-align: center;
      color: #354052;
      padding: 20px 20px 30px;
      font-size: 16px;
      font-weight: normal;
    }
  }
  .side_1 {
    color: #03ad79;
  }
  .side_2 {
    color: #c61b3d;
  }
  .status_1 {
    color: #f78700;
  }
  .status_2 {
    color: #03ad79;
  }
  .status_3 {
    color: #bec1c8;
  }
  .add-btn {
    /* width: 70px; */
    cursor: pointer;
    height: 24px;
    padding: 5px 5px 5px;
    // line-height: 24px;
    border-radius: 2px;
    border: solid 1px #e2e2e4;
    background-color: #ffffff;
    color: #3a7dff;
    border-color: #3a7dff;
    font-size: 14px;
    line-height: 12px;
    font-weight: 500;
    outline: none;
    &:hover {
      background-color: #ffffff;
      color: #3a7dff;
      border: solid 1px #e2e2e4;
      outline: none;
    }
    &:focus {
      background-color: #ffffff;
      color: #3a7dff;
      border: solid 1px #e2e2e4;
      outline: none;
    }
  }
  .cancel-btn {
    text-align: center;
    // width: 52px;
    height: 24px;
    padding: 5px 5px 5px;
    // line-height: 24px;
    border-radius: 2px;
    border: solid 1px #e2e2e4;
    background-color: #ffffff;
    color: #3a7dff;
    border-color: #3a7dff;
    font-size: 14px;
    line-height: 12px;
    font-weight: 500;
    outline: none;
    &:hover {
      background-color: #ffffff;
      color: #3a7dff;
      border: solid 1px #e2e2e4;
      outline: none;
    }
    &:focus {
      background-color: #ffffff;
      color: #3a7dff;
      border: solid 1px #e2e2e4;
      outline: none;
    }
  }
}
</style>
