<template>
  <div class="chat-wedget">
    <el-badge :value="noRead" class="item" :hidden="!noRead" :max="99">
      <div
        class="chat-wedget-circle"
        v-if="!visible"
        @click="onChatClick"
      ></div>
    </el-badge>
    <div class="chat-wedget-box" v-show="visible" v-loading="frameLoad">
      <div class="close" @click="onClose">
        <i class="iconfont iconicon-close black" v-if="customer_type == 1"></i>
        <i class="iconfont iconicon-close" v-else></i>
      </div>
      <iframe id="iframe" :src="frameUrl" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapState } from "vuex";
import Cookies from "js-cookie";
import { apiGetcustermerUrl } from "@/model/home/index";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // const frameUrl = `https://customer-service.hxexchge.com/?token=${token}`;
    return {
      visible: false,
      frameUrl: "",
      token: "",
      customer_type: 0,
      customer_service_url: "",
      noRead: "",
      frameLoad: true,
    };
  },
  async created() {
    this.token = Cookies.get("topcredit_exchange_t");
    await this.getcustermerUrl();
    //  this.customer_service_url = sessionStorage.getItem("customer_service_url");
    let tempHost = window.location.host;
    // this.customer_type = sessionStorage.getItem("customer_type");

    if (this.customer_type == "1") {
      this.frameUrl = this.customer_service_url;
    } else {
      this.frameUrl =
        this.customer_service_url +
        `/?token=${this.token}&host=${tempHost}&lang=${this.language}#is_show`;
    }
    // this.startTimer();
  },
  mounted() {},
  methods: {
    async getcustermerUrl() {
      const { status, data } = await apiGetcustermerUrl();
      if (status == 200) {
        this.customer_service_url = data.customer_service_url;
        this.customer_type = data.customer_type;
      }
    },
    onChatClick() {
      this.visible = true;
      // let tempUrl = sessionStorage.getItem("customer_service_url");
      // this.customer_type = sessionStorage.getItem("customer_type");
      let tempHost = window.location.host;

      if (this.customer_type == "1") {
        this.frameUrl = this.customer_service_url;
      } else {
        this.frameUrl =
          this.customer_service_url +
          `/?token=${this.token}&host=${tempHost}&lang=${this.language}#is_show`;
      }
      setTimeout(() => {
        this.frameLoad = false;
      }, 1500);
    },
    onClose() {
      this.visible = false;
      this.frameLoad = true;
      // let tempUrl = sessionStorage.getItem("customer_service_url");
      // this.customer_type = sessionStorage.getItem("customer_type");
      let tempHost = window.location.host;

      if (this.customer_type == "1") {
        this.frameUrl = this.customer_service_url;
      } else {
        this.frameUrl =
          this.customer_service_url +
          `/?token=${this.token}&host=${tempHost}&lang=${this.language}#is_hide`;
      }
    },
    startTimer() {
      this.initStatus().then(rs => {
        setTimeout(() => {
          if (this.destroyed) {
            return;
          }
          this.startTimer();
        }, 10000);
      });
    },
    initStatus() {
      return apiIngOrder().then(rs => {
        const { status, data } = rs;
        if (status == 200) {
          this.noRead = +data.fast_gm_no_read;
          // this.noRead = (+1);
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
    ...mapState({
      userInfo: state => state.user.info,
    }),
  },
  watch: {
    dialogVisible() {
      this.onChatClick();
    },
  },
};
</script>

<style lang="scss">
.chat-wedget {
  position: fixed;
  right: 40px;
  bottom: 7%;
  .el-badge__content.is-fixed {
    top: -48px !important;
  }

  cursor: pointer;
  z-index: 99999;
  .chat-wedget-circle {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #c61b3d url("../../assets/img/chat-wedget-white.png") no-repeat
      center;
    background-size: 60%;
  }
  .chat-wedget-box {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 375px;
    height: 650px;
    box-shadow: 10px 10px 10px 0 hsla(0, 0%, 77.6%, 0.2);
    border-radius: 4px;
    overflow: hidden;
    iframe {
      width: 100%;
      height: 100%;
    }
    .close {
      position: absolute;
      left: 10px;
      top: 8px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .iconicon-close {
        color: white;
      }
    }
  }
}
</style>
